import React from "react";
import MetaTags from "react-meta-tags";

export class MetaHBProCovid extends React.Component {
    render() {
        return (
            <div className="wrapper">
                <MetaTags>
                    <title>Housebuilder Pro Site Tracker</title>
                    <meta property="og:title" content="Housebuilder Pro Site Tracker" />
                    <meta property="og:url" content="https://sitetracker.housebuilderpro.co.uk" />
                    <meta
                        property="og:description"
                        content="A free-to-use visitor registration system designed to help pubs and bars to easily follow Government guidelines to help support the NHS Test & Trace effort automatically."
                    />
                    <meta property="og:image" content="https://sitetracker.housebuilderpro.co.uk/resources/coverhbpro.jpg" />
                </MetaTags>
            </div>
        );
    }
}
