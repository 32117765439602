/* eslint-disable @typescript-eslint/no-non-null-assertion */
//Based on https://github.com/wsmd/react-use-form-state

import { FieldType, generateID } from "../Utils/Utils";
import { IObservableArray, isObservableArray, observable, runInAction } from "mobx";
import { ObjPathProxy, getPath } from "ts-object-path";
import { useCallback, useEffect, useMemo, useReducer, useRef, useState } from "react";

import { EditableInputProps } from "./EditableInput";
import { IModel } from "../Models";
import { IViewModel } from "../ViewModels";
import { Moment } from "moment";
import React from "react";

export const CHECKBOX = "checkbox";
export const COLOR = "color";
export const DATE = "date";
export const EMAIL = "email";
export const MONTH = "month";
export const NUMBER = "number";
export const PASSWORD = "password";
export const RADIO = "radio";
export const RANGE = "range";
export const SEARCH = "search";
export const SELECT = "select";
export const TEL = "tel";
export const TEXT = "text";
export const TIME = "time";
export const URL = "url";
export const WEEK = "week";
export const MULTISELECT = "multiselect";
/**
 * @todo add support for datetime-local
 */
export const DATETIME_LOCAL = "datetime-local";
/**
 * @todo add support for a multiple select
 */
export const MULTIPLE = "multiple";

export const TYPES = [CHECKBOX, COLOR, DATE, EMAIL, MONTH, NUMBER, PASSWORD, RADIO, RANGE, SEARCH, SELECT, TEL, TEXT, NUMBER, TIME, URL, WEEK, MULTISELECT];

type Maybe<T> = T;

type TModel<T> = IModel<T> & any;

interface InputOptions {
    id?: string;
    validateOnBlur?: boolean;
    alphaOnly?: boolean;
}

interface Inputs<T extends TModel<T>> {
    select(fieldName: keyof FieldType<T>, inputOptions?: InputOptions): Omit<InputProps, "type">;
    email(fieldName: keyof FieldType<T>, inputOptions?: InputOptions): InputProps;
    color(fieldName: keyof FieldType<T>, inputOptions?: InputOptions): InputProps;
    password(fieldName: keyof FieldType<T>, inputOptions?: InputOptions): InputProps;
    text(fieldName: keyof FieldType<T> | string, inputOptions?: InputOptions): InputProps;
    number(fieldName: keyof FieldType<T>, inputOptions?: InputOptions): InputProps;
    url(fieldName: keyof FieldType<T>, inputOptions?: InputOptions): InputProps;
    search(fieldName: keyof FieldType<T>, inputOptions?: InputOptions): InputProps;
    number(fieldName: keyof FieldType<T>, inputOptions?: InputOptions): InputProps;
    range(fieldName: keyof FieldType<T>, inputOptions?: InputOptions): InputProps;
    tel(fieldName: keyof FieldType<T>, inputOptions?: InputOptions): InputProps;
    radio(
        fieldName: keyof FieldType<T>,
        inputOptions: InputOptions | null,
    ): //ownValue: string,
    Omit<InputProps & CheckedProp, "type">;
    checkbox(fieldName: keyof FieldType<T>, inputOptions?: InputOptions): Omit<InputProps & CheckedProp, "type">;
    date(fieldName: keyof FieldType<T>, inputOptions?: InputOptions): InputProps;
    month(fieldName: keyof FieldType<T>, inputOptions?: InputOptions): InputProps;
    week(fieldName: keyof FieldType<T>, inputOptions?: InputOptions): InputProps;
    time(fieldName: keyof FieldType<T>, inputOptions?: InputOptions): InputProps;
    multiselect(fieldName: keyof FieldType<T>, inputOptions?: InputOptions): InputProps;
}

interface CheckedProp {
    checked: boolean;
}

interface InputProps {
    onChange(e: any): void;
    onBlur(e: any): void;
    onFocus(e: any): void;
    checked: boolean;
    value: string | number | string[] | undefined;
    id: string;
    type?: string;
    error: boolean;
}

export function Validation<T extends TModel<T>>(viewModel: IViewModel<T>): [ObjPathProxy<T, T>, Inputs<T>] {
    // const formHandler = useState(defaultValues);
    //const [mounted, setMounted] = useState(false);
    const uniqueId = useRef(generateID());

    // initial mounted flag
    useEffect(() => {}, []);

    const validate = (fieldName: keyof FieldType<T>, value: any) => {
        // if (props.required) {
        // 	if (props.type === "text") {
        // 		let isValid = value !== "";
        // 		props.viewModel.setValid(props.fieldName, isValid);
        // 		props.viewModel.setError(props.fieldName, `${props.label} is required.`);
        // 		return;
        // 	}
        // }
        viewModel.isFieldValid(fieldName, value);
    };

    const createPropsGetter = (type: any) => (fieldName: keyof FieldType<T>, inputOptions: InputOptions | null, ownValue: string = "") => {
        if (!inputOptions) {
            inputOptions = {};
        }
        //EN: If we are passing in a proxy convert it back into an object string path
        if (typeof fieldName !== "string") {
            let p = getPath(fieldName as string);
            // @ts-ignore
            fieldName = p.join(".") as string;
        }

        const onDateChange = (value: Moment) => {
            if (value) {
                let d = value.toDate();
                if (!isNaN(d.getTime())) {
                    viewModel.setValue<Date>(fieldName, d);
                    viewModel.setDirty(fieldName, true);

                    if (!inputOptions!.validateOnBlur) {
                        validate(fieldName, d.toISOString());
                    }
                }
            }
        };

        const getTargetValue = (e: any): string | boolean | Date | number | null | any[] => {
            let value: string | number | boolean | Date | Moment | null;
            if (type === "number") {
                value = e.target.value;
                if (isNaN(parseFloat(value as any))) {
                    return null;
                }
                return parseFloat(value as any);
            } else if (type === "date") {
                return (e as Moment).toISOString();
                //return (e as Moment).toDate();
            } else if (type === "checkbox") {
                return e.target.checked;
            } else {
                return e.target.value;
            }
        };

        const inputProps = {
            get name() {
                return fieldName;
            },
            get id() {
                if (type !== SELECT && type !== RADIO) {
                    if (!inputOptions!.id || inputOptions!.id === "") {
                        return fieldName + "-" + uniqueId.current;
                    }
                    return inputOptions!.id;
                }
                return inputOptions!.id;
            },
            get error() {
                if (!viewModel.getValid(fieldName)) {
                    return true;
                }
                return undefined;
            },
            get type() {
                if (type === DATE || type === TIME) {
                    return "text";
                }
                if (type !== SELECT && type !== RADIO) {
                    return type;
                }
                if (type === RADIO) {
                    return "radio";
                }
                return undefined;
            },
            get checked() {
                return viewModel.getValue(fieldName);
            },
            get value() {
                const value = viewModel.getValue(fieldName);
                if (type === MULTISELECT && isObservableArray(value)) {
                    return (value as IObservableArray).toJS();
                }
                return value;
            },
            get multiple() {
                return type === MULTISELECT ? true : undefined;
            },
            onClick(e: any) {
                const { value: targetValue, checked } = e.target;
                if (type === RADIO) {
                }
            },
            onChange(e: React.ChangeEvent<HTMLInputElement>) {
                let value = getTargetValue(e);
                const currentValue = viewModel.getValue(fieldName);
                if (inputOptions?.alphaOnly) {
                    try {
                        value = value!.toString().replace(/[^A-Za-z]/gi, "");
                    } catch (e) {}
                }

                if (type === MULTISELECT && isObservableArray(currentValue)) {
                    viewModel.setValue(fieldName, observable(value! as any[]));
                } else {
                    viewModel.setValue(fieldName, value!);
                }

                viewModel.setDirty(fieldName, true);

                if (!inputOptions!.validateOnBlur) {
                    validate(fieldName, value);
                }
            },
            onFocus(e: React.ChangeEvent<HTMLInputElement>) {
                viewModel.setTouched(fieldName, true);
            },
            onBlur(e: React.ChangeEvent<HTMLInputElement>) {
                let value = getTargetValue(e);

                if (inputOptions!.validateOnBlur) {
                    validate(fieldName, value);
                }
            },
        };
        return inputProps;
    };

    const typeMethods = TYPES.reduce(
        (methods: any, type: string) => ({
            ...methods,
            [type]: createPropsGetter(type),
        }),
        {},
    );

    return [viewModel.getContext(), typeMethods];
}
