import { AccountStatus, ApiResult } from "Core/Models";
import { RegisterUserModel } from "Custom/Models/RegisterUserModel";
import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import validator from "validator";
import { FieldType, isNullOrEmpty } from "../../Core/Utils/Utils";
import { RegisterAddressModel } from "../Models/RegisterUserAddressModel";
import { StoresInstance } from "../Stores";
import { computed, observable, runInAction } from "mobx";
import { pipe, gotenberg, convert, html, please, url } from "gotenberg-js-client";
import Axios from "axios";
import { CovidRegModel } from "../Models/CovidRegModel";

export enum SubPage {
    Dashboard,
    Registrations,
}
//Pass in <any> as the generic type because we are showing the use of nested classes
export class RegisterUserViewModel extends ViewModelBase<RegisterUserModel> {
    @observable public HasImage = false;
    @observable public subPage = SubPage.Dashboard;
    @observable public Registrations = [] as CovidRegModel[];
    constructor() {
        super(new RegisterUserModel());
        console.log("Register View Model Created");
        this.setDecorators(RegisterUserModel);
    }

    public downloadDocument = async () => {
        let response = (await this.Get("/api/covid/createfromurl/" + this.getValue("displayName"), {
            responseType: "blob",
        })) as any;
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "qrdocument.pdf"); //or any other extension
        document.body.appendChild(link);
        link.click();
    };

    public getRegistrations = async () => {
        let response = await this.Get<CovidRegModel[]>("/api/covid/getregistrations/");
        if (response.wasSuccessful) {
            runInAction(() => {
                response.payload.forEach((item, index) => {
                    let domain = new CovidRegModel();
                    domain.fromDto(item);
                    this.Registrations.push(domain);
                });
            });
        }
    };

    public downloadCSV = async () => {
        let url = "/api/covid/downloadcsv/";
        if (StoresInstance.domain.isHbProSite) {
            url = "/api/covid/downloadcsvforhbpro/";
        }
        let response = (await this.Get(url, {
            responseType: "blob",
        })) as any;
        url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "registrations.csv"); //or any other extension
        document.body.appendChild(link);
        link.click();
    };

    public downloadQRCode = () => {};

    public register = async (): Promise<ApiResult<AccountStatus>> => {
        this.setValue("isHBPro", StoresInstance.domain.isHbProSite);
        let apiResult = await this.Post<AccountStatus>("/api/account/register", this.getModelAsPayload());
        if (apiResult.errors.length > 0) {
            if (apiResult.errors[0].message == "Registration failed as the display name is already taken.") {
                this.setError("displayName", apiResult.errors[0].message);
                this.setValid("displayName", false);
            }
        }
        if (apiResult && apiResult.wasSuccessful) {
            StoresInstance.domain.AccountStore.getLoginState(apiResult.payload);
        }
        return apiResult;
    };

    public getUserDetails = async (): Promise<ApiResult<RegisterUserModel>> => {
        let response = await this.Get<RegisterUserModel>("/api/account/getuserdetails/" + StoresInstance.domain.AccountStore.DisplayName);
        runInAction(() => {
            let userModel = new RegisterUserModel();
            userModel.fromDto(response.payload);
            this.setModel(userModel);
            this.HasImage = true;
        });
        return response;
    };

    @computed
    public get getLogoUrl() {
        return `https://shoothillweb2general.blob.core.windows.net/covidtracer/logos/${this.getValue("displayName")}_thumb.jpg`;
    }

    public isFieldValid(fieldName: keyof FieldType<RegisterUserModel>, value: any): boolean {
        let { isValid, errorMessage } = this.validateDecorators(fieldName);

        if (StoresInstance.domain.AccountStore.IsLoggedIn) {
            if (fieldName === "terms") {
                isValid = true;
                errorMessage = "";
            }
            if (fieldName === "password") {
                if (value === null || value === "") {
                    isValid = true;
                    errorMessage = "";
                }
            }
        }
        //Overriding the decorators to check password fields
        if (fieldName === "confirmPassword") {
            if (this.getValue("password") !== null && this.getValue("password") !== "") {
                errorMessage = this.isConfirmPasswordValid;
                isValid = errorMessage === "";
            }
        }

        if (!StoresInstance.domain.isHbProSite && fieldName === "tradingName") {
            isValid = true;
            errorMessage = "";
        }

        if (!isValid) {
            console.log(fieldName + " is invalud with value " + value);
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public get isConfirmPasswordValid(): string {
        if (this.getValue("password") !== this.getValue("confirmPassword") && this.getValue("password") !== "") return "Passwords must match";
        return "";
    }
}
