import React from "react";
import { Box, Container, Typography } from "@material-ui/core";
import { CSSGrid, Cell } from "../../Core/Components/Styles";
import backGround from "Custom/Content/Images/bg.png";
import hbProBackGround from "Custom/Content/Images/brickscropped.jpg";
import styled from "styled-components";
import { useObserver } from "mobx-react-lite";
import { StoresInstance } from "../Stores";
import { useMediaQuery } from "../../Core/Utils/Hooks/UseMediaQuery";
import { useLocation } from "react-router-dom";
import { MetaCovid } from "../Components/MetaCovid";
import { MetaHBProCovid } from "../Components/MetaHBProCovid";

export const MainLayout = styled(Box)``;

const getBackground = () => {
    /*if (StoresInstance.domain.CurrentSummary && StoresInstance.domain.CurrentSummary.isHBPro) {
        return `https://shoothillweb2general.blob.core.windows.net/covidtracer/logos/${StoresInstance.domain.CurrentSummary.displayName}_thumb.jpg`;
    } else */
    if (StoresInstance.domain.isHbProSite) {
        return hbProBackGround;
    } else {
        return backGround;
    }
};

const Background = styled(Box)`
    /*top: 0;
    position: absolute;
    width: 100vw;
    height: 100vh;*/
    z-index: -1;
    padding-bottom: 100px;
    background: url(${getBackground()}) repeat center center;
    //background-size: cover;
    background-attachment: fixed;
    height:1280px;
    //overflow: hidden;
    
    /*> div {
        //transform: scale(1.2);
        //position: relative;
        //top: -85px;
        width: 100%;
        height: 100%;
        z-index: -1;
        background: url(${getBackground()}) repeat center center;
        //background-size: cover;
        background-attachment: fixed;
        /!* ${(props) => props.theme.breakpoints.up("sm")} {
            height: 1400px;
        } *!/

        @media (max-width: 767px) {
            top: 0;
            left: 0;
            transform: scale(1.3);
            background-position-x: -213px;
            background-position-y: -65px;
        }
    }*/
`;

//EN: use empty generic type to get default react props
export const Layout: React.FC<{}> = (props) => {
    const isMobile = !!useMediaQuery("(max-width: 767px)");

    const showMobileImage = () => {
        let loc = useLocation();
        if (isMobile && loc.pathname.indexOf("/qr/") < 0) {
            return <img src={getBackground()} width={"100%"} />;
        }
    };

    const isHBPROSite = () => {
        if (StoresInstance.domain.isHbProSite || location.href.indexOf("sitetracker") >= 0) {
            return <MetaHBProCovid />;
        }

        return <MetaCovid />;
    };

    return useObserver(() => (
        <Background id={"backgrounddiv"}>
            <MainLayout id={"layoutmain"}>
                {isHBPROSite()}
                {showMobileImage()}

                <CSSGrid columns={"1fr"}>
                    {/*<Cell area={"header"}>*/}
                    {/*	<Header />*/}
                    {/*</Cell>*/}
                    {props.children}
                </CSSGrid>
                {!isMobile && <div />}
            </MainLayout>
        </Background>
    ));
};
