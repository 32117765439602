/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState, useEffect } from "react";
import { StoresInstance } from "Custom/Stores";
import { useRouter } from "../../Core/Utils/Hooks";
import { CovidRegViewModel } from "../ViewModels/CovidRegViewModel";
import { useObserver } from "mobx-react-lite";
import { Box, Typography } from "@material-ui/core";
import QRCode from "react-qr-code";
import styled from "styled-components";
import ScanMe from "Custom/Content/Images/scanme.jpg";
import logoShoothill from "../Content/Images/Shoothill-logo.svg";
import { FooterSwitcher } from "../Components/FooterSwitcher";
import { Show } from "../../Core/Base";

const Main = styled(Box)`
    width: 100vw;
    height: 100vh;
    background-color: white;

    @media print {
        .hide {
            display: none;
        }
    }
`;

const Paper = styled(Box)`
    width: 700px;
    height: 842px;
    min-height: 842px;

    background-color: white;
`;

export const QR: React.FC = () => {
    const [viewModel] = useState(() => new CovidRegViewModel());
    const { history, match } = useRouter();
    let { displayName } = match.params as any;

    useEffect(() => {
        let _ = viewModel.getSummary(displayName);
    }, []);

    return useObserver(() => (
        <Main id={"main"}>
            <a className={"hide"} style={{ cursor: "pointer" }} onClick={() => viewModel.history.push("/dashboard")}>
                {" "}
                Back
            </a>
            <Paper id={"covidcontainer"} className={viewModel.isMobile ? "covidcontainer-mobile" : "covidcontainer"}>
                <Box textAlign={"center"} p={3}>
                    <Box pb={2}>
                        <Typography variant={"h3"}>
                            <strong>{viewModel.Summary.tradingName}</strong>
                        </Typography>
                    </Box>
                    <Show iff={StoresInstance.domain.isHbProSite}>
                        <Typography variant={"h6"}>
                            <strong>To follow Government guidelines, we require all visitors to register when they arrive or leave these premises.</strong>
                        </Typography>
                        <br />
                        <br />
                        <Typography variant={"h6"}>
                            <strong>Please scan the below QR code with your smartphone camera on arriving and leaving at this address</strong>
                        </Typography>
                    </Show>
                    <Show iff={!StoresInstance.domain.isHbProSite}>
                        <Typography variant={"h6"}>
                            <strong>
                                To follow Government guidelines due to the Covid-19 pandemic, we require all visitors to register their details by scanning the QR code below.
                            </strong>
                        </Typography>
                    </Show>
                </Box>
                <hr />
                <Box py={4} textAlign={"center"}>
                    <QRCode value={`https://${location.hostname}:${location.port}/trace/${viewModel.Summary.displayName}`} size={256} />
                    <Box>
                        <img src={ScanMe} />
                    </Box>
                    <Box py={4}>
                        <Typography variant={"caption"}>Or enter the following web address and fill in the form</Typography>
                        <Typography variant={"h4"}>
                            <strong style={{ fontFamily: "monospace" }}>{viewModel.Summary.bitly}</strong>
                        </Typography>
                    </Box>
                </Box>
                <FooterSwitcher />
                {/*<Box position={"relative"} bottom={"0"} left="0" textAlign="center" margin="auto">
                    <img src={logoShoothill} alt="Shoothill Ltd." className="shoothill" />
                    <Typography variant="subtitle2">© {new Date().getFullYear()} Shoothill Ltd.</Typography>
                </Box>*/}
            </Paper>
        </Main>
    ));
};
