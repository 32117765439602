import React, { useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import styled from "styled-components";
import { RegisterUserViewModel, SubPage } from "../../ViewModels/RegisterUserViewModel";
import { StoresInstance } from "../../Stores";
import { useObserver } from "mobx-react-lite";
import { Covidcontainer } from "../../StylesAppSpecific/HomeStyling";
import { RegisterCompanyDetails } from "./RegisterCompanyDetails";
import { useRouter } from "../../../Core/Utils/Hooks";
import { runInAction } from "mobx";
import { Show } from "../../../Core/Base";
import { RegistrationsView } from "./RegistrationsView";
import { getHistory } from "../../../Core/Utils/Utils";
import { LogoSwitcher } from "../../Components/LogoSwitcher";

export const Thumb = styled.img`
    max-height: 100px;
`;

export const RegisterPage: React.FC = () => {
    const [viewModel] = useState(() => new RegisterUserViewModel());
    const { history, match } = useRouter();
    //const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    useEffect(() => {
        const { subpage } = match.params as any;
        if (subpage && subpage === "registrations") {
            runInAction(() => {
                showRegistrations();
            });
        }
        if (StoresInstance.domain.AccountStore.IsLoggedIn) {
            let _ = viewModel.getUserDetails();
        }
    }, []);

    const showRegistrations = () => {
        getHistory().push("/dashboard/registrations");
        runInAction(() => {
            viewModel.subPage = SubPage.Registrations;
            if (viewModel.Registrations.length == 0) {
                viewModel.getRegistrations();
            }
        });
    };

    const showDashboard = () => {
        getHistory().push("/dashboard");
        runInAction(() => {
            viewModel.subPage = SubPage.Dashboard;
        });
    };

    return useObserver(() => (
        <Box id={"main"}>
            <Covidcontainer id={"covidcontainer"}>
                <Box pb={5}>
                    <LogoSwitcher />
                </Box>
                <Show iff={StoresInstance.domain.AccountStore.IsLoggedIn}>
                    <Box
                        display={"flex"}
                        padding={viewModel.isMobile ? "10px 0 2px 45px" : "5px 0 2px 45px"}
                        height={"34px"}
                        style={{ backgroundColor: "#449B23", color: "white", position: "relative", left: "-45px", width: "829px" }}
                    >
                        <Box style={{ cursor: "pointer" }}>
                            <Typography variant={"caption"} onClick={showDashboard}>
                                Company Details
                            </Typography>
                        </Box>
                        <Box pl={4} pr={4}>
                            |
                        </Box>
                        <Box style={{ cursor: "pointer" }}>
                            <Typography variant={"caption"} onClick={showRegistrations}>
                                Registrations
                            </Typography>
                        </Box>
                        <Box pl={4} pr={4}>
                            |
                        </Box>
                        <Box style={{ cursor: "pointer" }}>
                            <Typography variant={"caption"} onClick={() => getHistory().push("/logout")}>
                                Logout
                            </Typography>
                        </Box>
                    </Box>
                </Show>
                <Show iff={viewModel.subPage == SubPage.Dashboard}>
                    <RegisterCompanyDetails viewModel={viewModel} />
                </Show>
                <Show iff={viewModel.subPage == SubPage.Registrations}>
                    <RegistrationsView viewModel={viewModel} />
                </Show>
            </Covidcontainer>
        </Box>
    ));
};
