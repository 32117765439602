import { action, computed, observable } from "mobx";
import { FieldType, isEmptyOrWhitespace } from "Core/Utils/Utils";
import { LoginModel } from "../Models";
import { AccountStatus, ApiResult } from "Core/Models";
import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { TestModel } from "../../Core/Models/TestModel";
import { StoresInstance } from "../../Core/Base";
import { getHistory } from "../../Core/Utils/Utils";

const domainStores = StoresInstance.domain;

export class LoginViewModel extends ViewModelBase<LoginModel> {
    @observable
    public errorMessage = "";

    constructor() {
        super(new LoginModel());
        console.log("Login View Model Created");
        //EN - Havent figured out how to make this call work from the base model yet
        //This is only needed if you make use of the validation decorators
        this.setDecorators(LoginModel);
        //(window as any).model = this.model;
        //(window as any).proxy = proxy;

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    public async handleSubmit(e: React.FormEvent) {
        try {
            e.preventDefault();
            if (this.isModelValid()) {
                const apiResult = await this.login();
                if (apiResult && apiResult.wasSuccessful) {
                    domainStores.AccountStore.getLoginState(apiResult.payload);
                    const { from } = (getHistory().location.state as any) || { from: { pathname: "/dashboard" } };
                    if (from) {
                        getHistory().push(from.pathname);
                    }
                } else {
                    // Errors caught below if no error message or failed request
                    this.setErrorMessage(apiResult.errors[0].message);
                }
            }
        } catch {
            this.setErrorMessage("An unknown error occured");
        }
    }

    @action
    public setErrorMessage(message: string) {
        this.errorMessage = message;
    }

    public login = async (): Promise<ApiResult<AccountStatus>> => {
        let apiResult = await this.Post<AccountStatus>("/api/account/login", this.getModel);
        return apiResult;
    };

    public isFieldValid(fieldName: "emailAddress" | "password", value: any): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    //public afterUpdate: undefined;
    afterUpdate(fieldName: keyof FieldType<LoginModel>, value: any): void {
        //Do something like call an api or whatever/
        //Can use this instead of mobx reaction
        return;
    }
    //public beforeUpdate: undefined;
    beforeUpdate(fieldName: keyof FieldType<LoginModel>, value: any): any {
        //Do something and return the new value if you want or just use it to perform another action
        if (value === "something" && fieldName === "emailAddress") {
            value = "boo";
        }
        return value;
    }

    @computed
    public get isEmailAddressValid(): string {
        let retval = "";
        if (isEmptyOrWhitespace(this.getValue<string>("emailAddress"))) return "Email address is required";
        if (this.getValue<string>("emailAddress").length < 4) return "Email address must be greater than 4 characters";

        return retval;
    }

    @computed
    public get isPasswordValid(): string {
        let retval = "";
        if (isEmptyOrWhitespace(this.getValue("password") as string)) return "Password is required";
        if (this.getValue<string>("password").length < 4) return "Password must be greater than 4 characters";

        return retval;
    }
}
