import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import { RegisterUserModel } from "../Models/RegisterUserModel";
import { RegisterUserViewModel } from "../ViewModels/RegisterUserViewModel";
import { Box } from "@material-ui/core";
import { CSSGrid } from "../../Core/Components/Styles";

const getColor = (props: any) => {
    if (props.isDragAccept) {
        return "#00e676";
    }
    if (props.isDragReject) {
        return "#ff1744";
    }
    if (props.isDragActive) {
        return "#2196f3";
    }
    return "#eeeeee";
};

const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 40px;
    border-width: 2px;
    border-radius: 2px;
    border-color: ${(props) => getColor(props)};
    border-style: dashed;
    background-color: #e8e8e8;
    color: #3f3f3f;
    outline: none;
    transition: border 0.24s ease-in-out;
`;
const thumbsContainer = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16,
};

const thumb = {
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: "border-box",
};

const thumbInner = {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
    justifyContent: "center",
};

const img = {
    display: "block",
    width: "auto",
    //height: "100%",
    maxWidth: "180px",
};
interface Props {
    viewModel: RegisterUserViewModel;
}
export const UploadLogo = (props: Props) => {
    const [files, setFiles] = useState([]);
    const [hasImage, setHasImage] = useState(false);
    const onDrop = useCallback((acceptedFiles) => {
        setFiles(
            acceptedFiles.map((file: any) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = (event) => {
                    if (event && event.target) {
                        props.viewModel.setValue("logoBase64", event!.target!.result);
                    }
                    setHasImage(true);
                    // this.setState({
                    //     generalPhotos: this.state.generalPhotos.concat([{ base64: event.target.result }])
                    // });
                };
                return Object.assign(file, {
                    preview: URL.createObjectURL(file),
                });
            }),
        );
    }, []);
    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({ onDrop: onDrop, accept: "image/*" });
    useEffect(
        () => () => {
            // Make sure to revoke the data uris to avoid memory leaks
            files.forEach((file) => URL.revokeObjectURL((file as any).preview));
        },
        [files],
    );
    const thumbs = files.map((file: any) => (
        <div key={file.name}>
            <div style={thumbInner}>
                <img src={file.preview} style={img} />
            </div>
        </div>
    ));
    return (
        <>
            {/*<CSSGrid columns={"1fr 200px"}>*/}
            {!hasImage && (
                <Box>
                    <Container {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
                        <input {...getInputProps()} />
                        <p>Drag file or click to add your business logo (optional)</p>
                    </Container>
                </Box>
            )}
            {hasImage && (
                <>
                    <Box>
                        <aside
                            onClick={() => {
                                setHasImage(false);
                            }}
                        >
                            {thumbs}
                        </aside>
                        <Box textAlign={"center"}>
                            <i>* Click image to remove</i>
                        </Box>
                    </Box>
                </>
            )}
            {/*</CSSGrid>*/}
        </>
    );
};
