import { RegisterUserViewModel, SubPage } from "../../ViewModels/RegisterUserViewModel";
import { Box, Button, Link, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useObserver } from "mobx-react-lite";
import MaterialTable from "material-table";
import inImage from "../../Content/Images/in.png";
import outImage from "../../Content/Images/out.png";
import { FormatDate } from "../../../Core/Utils/Format";
import { getHistory } from "../../../Core/Utils/Utils";
import { StoresInstance } from "../../Stores";

const Infotext = styled(Box)`
    color: gray;
    font-size: 12px;
`;

export function RegistrationsView(props: { viewModel: RegisterUserViewModel }) {
    const { viewModel } = props;

    useEffect(() => {}, []);

    const getColumns = () => {};

    return useObserver(() => (
        <>
            <Typography>{viewModel.getValue("isHBPro")}</Typography>
            <Box display={"flex"} padding={viewModel.isMobile ? "0" : "20px 0 20px"} justifyContent={"space-between"}>
                <Typography variant={"h5"}>{viewModel.getValue("isHBPro") == true ? <strong>Visitor/Contractor ins and outs</strong> : <strong>Registrations</strong>}</Typography>
                <Button disabled={viewModel.IsLoading} variant="contained" color="secondary" onClick={() => viewModel.downloadCSV()}>
                    Download spreadsheet
                </Button>
            </Box>
            <Box>
                <MaterialTable
                    columns={[
                        {
                            title: "",
                            field: "signIn",
                            render: (rowData) => {
                                if (rowData.signIn) {
                                    return <img height="24px" src={inImage} />;
                                } else if (rowData.signOut) {
                                    return <img height="24px" src={outImage} />;
                                }
                                return "NA";
                            },
                            hidden: !StoresInstance.domain.isHbProSite,
                        },
                        {
                            title: "Date / Time",
                            field: "dateRegistered",
                            render: (rowData) => {
                                return FormatDate(rowData.dateRegistered, "DD MMM, YYYY") + " @ " + FormatDate(rowData.dateRegistered, "HH:mm");
                            },
                        },
                        { title: "Name", field: "lastName", render: (rowData) => rowData.firstName + " " + rowData.lastName },
                        { title: "Company", field: "company", hidden: !StoresInstance.domain.isHbProSite },
                        { title: "Contact Number", field: "phoneNumber" },
                    ]}
                    isLoading={viewModel.IsLoading}
                    options={{
                        pageSize: 50,
                        pageSizeOptions: [10, 20, 50, 100],
                        emptyRowsWhenPaging: false,
                    }}
                    data={viewModel.Registrations.slice()}
                    title=""
                />
            </Box>
        </>
    ));
}
