import { AccountStatus, ApiResult } from "Core/Models";
import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import validator from "validator";
import { FieldType } from "../../Core/Utils/Utils";
import { CovidRegModel } from "../Models/CovidRegModel";
import { Summary } from "../Models/Summary";
import { action, computed, observable, runInAction } from "mobx";
import { StoresInstance } from "../Stores";

//Pass in <any> as the generic type because we are showing the use of nested classes
export class CovidRegViewModel extends ViewModelBase<CovidRegModel> {
    public Summary = new Summary();

    @observable public showLoading: boolean = true;
    @observable public showSignInPage: boolean = false;

    constructor() {
        super(new CovidRegModel());
        console.log("Register View Model Created");
        this.setDecorators(CovidRegModel);
    }

    public getSummary = async (displayName: string) => {
        let response = await this.Get<Summary>("/api/account/getsummary/" + displayName);
        runInAction(() => {
            if (response.payload) {
                this.Summary.userId = response.payload.userId;
                this.Summary.tradingName = response.payload.tradingName;
                this.Summary.displayName = response.payload.displayName;
                this.Summary.bitly = response.payload.bitly;
                this.Summary.isHBPro = response.payload.isHBPro;
                this.showSignInPage = StoresInstance.domain.isHbProSite;
                StoresInstance.domain.CurrentSummary = this.Summary;
                this.showLoading = false;
            }
        });
    };

    @action
    public SetShowSignInPage(state: boolean) {
        this.showSignInPage = state;
    }

    @action
    public SignIn() {
        this.showSignInPage = false;
        this.setValue("signIn", true);
        this.setValue("signOut", false);
    }

    @action
    public SignOut() {
        this.showSignInPage = false;
        this.setValue("signIn", false);
        this.setValue("signOut", true);
    }

    @computed
    public get getLogoUrl() {
        return `https://shoothillweb2general.blob.core.windows.net/covidtracer/logos/${this.Summary.displayName}_thumb.jpg`;
    }

    public sendForm = async (): Promise<ApiResult<CovidRegModel>> => {
        this.setValue("userId", this.Summary.userId);
        let response = await this.Post<CovidRegModel>("/api/covid/upsert", this.getModel);
        return response;
    };

    public isFieldValid(fieldName: keyof FieldType<CovidRegModel>, value: any): boolean {
        let { isValid, errorMessage } = this.validateDecorators(fieldName);

        if (StoresInstance.domain.isHbProSite && fieldName == "company") {
            if (value === "") {
                errorMessage = "This field is required.";
                isValid = false;
            }
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
