import React from "react";
import MetaTags from "react-meta-tags";

export class MetaCovid extends React.Component {
    render() {
        return (
            <div className="wrapper">
                <MetaTags>
                    {/*
                    <title>Page 1</title>
                    <meta name="description" content="Some description." />
                    <meta property="og:title" content="MyApp" />
                    <meta property="og:image" content="path/to/image.jpg" />
*/}

                    <title>Shoothill Covid 19 Trace System</title>
                    <meta property="og:title" content="Shoothill Covid 19 Trace System" />
                    <meta property="og:url" content="https://shoothillcovidtracer.com" />
                    <meta
                        property="og:description"
                        content="A free-to-use visitor registration system designed to help pubs and bars to easily follow Government guidelines to help support the NHS Test & Trace effort automatically."
                    />
                    <meta property="og:image" content="https://shoothillcovidtracer.com/resources/cover.jpg" />
                </MetaTags>
            </div>
        );
    }
}
