import * as GoogleAnalytics from "Core/Utils/App/GoogleAnalyticsUtils";
import * as Models from "../Models";
import { action, computed, observable, runInAction } from "mobx";
import { AccountStore } from "./Domain";
import { RoleStore } from "./Domain/Admin";
import { Stores } from "./Stores";
import { UserStore } from "./Domain/Admin";
import { RegisterUserModel } from "../Models/RegisterUserModel";
import { Summary } from "../Models/Summary";

export class DomainStores {
    @observable public openSnackBar = false;
    @observable public openErrorDialog = false;
    @observable public errorDialogContent: string = "";
    @observable public snackBarContent: string = "";
    @observable public isHbProSite: boolean = true;

    public AccountStore: AccountStore = new AccountStore();
    public UserStore = new UserStore();
    public RoleStore = new RoleStore();
    public CurrentUser = new RegisterUserModel();
    @observable public CurrentSummary: Summary | null = null;

    public init(stores: Stores, initialState: Models.InitialState) {
        this.AccountStore.init(stores, initialState);
    }
    @action
    public SetIsHBProSite(state: boolean) {
        this.isHbProSite = state;
    }
    @action
    public ShowSnackBar(content: string) {
        this.snackBarContent = content;
        this.openSnackBar = true;
    }

    @action
    public CloseSnackBar() {
        this.openSnackBar = false;
    }

    @action
    public ShowErrorDialog(content: string) {
        this.errorDialogContent = content;
        this.openErrorDialog = true;
    }
}
