import { Box, Button, Fade, Paper, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { Centered } from "Core/Components/Styles";
import { EditableInput } from "Core/Components/EditableInput";
import { ForgotPasswordModel } from "../Models";
import { ForgotPasswordViewModel } from "../ViewModels/ForgotPasswordViewModel";
import { useObserver } from "mobx-react-lite";
import { Covidcontainer } from "../StylesAppSpecific/HomeStyling";

export const ForgotPasswordPage = () => {
    const [viewModel] = useState(() => new ForgotPasswordViewModel());
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const doSubmit = async (e: any) => {
        e.preventDefault();
        if (viewModel.isModelValid()) {
            setIsLoading(true);
            let apiResult: any = await viewModel.ForgotPasswordAsync();
            setIsLoading(false);
            if (apiResult !== null) {
                if (apiResult.wasSuccessful) {
                    setIsSuccess(true);
                } else {
                    if (apiResult && apiResult.errors && apiResult.errors.length > 0) {
                        setErrorMessage("* " + apiResult.errors[0].message);
                    } else {
                        setErrorMessage("* Unknown error has occurred.");
                    }
                }
            }
        }
    };

    const goToForgotPassword = async (e: any) => {
        viewModel.history.push("/forgotpassword");
    };

    return useObserver(() => (
        <Box id={"maincontainer"} display={"flex"} height={"100%"} justifyContent={"center"} alignContent={"center"}>
            <Covidcontainer id={"covidcontainer"}>
                <Box padding="5px">
                    <Box justifyContent="center" textAlign="center" display="flex" flexDirection={"column"}>
                        <Typography component="h1" variant="h4" style={{ marginBottom: "25px", marginTop: "25px" }}>
                            Forgot password
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            If you have forgotten your password, please enter your email address below and we will send you a link to reset it.
                        </Typography>
                    </Box>
                    <form onSubmit={doSubmit}>
                        <Box p={5}>
                            {errorMessage !== "" && (
                                <Typography variant="caption" style={{ color: "red" }}>
                                    {errorMessage}
                                </Typography>
                            )}
                            <EditableInput<ForgotPasswordModel> type="email" label="Email Address" validateOnBlur={true} viewModel={viewModel} fieldName="emailAddress" />
                        </Box>
                        <Box>
                            <Button type="submit" fullWidth variant="contained" color="secondary" disabled={isLoading || isSuccess}>
                                {isLoading ? "Please Wait..." : "Forgot password"}
                            </Button>
                            {isSuccess && (
                                <Typography variant="h6" color="secondary">
                                    An email has been sent to the address above. Please check your inbox.
                                </Typography>
                            )}
                        </Box>
                    </form>
                </Box>
            </Covidcontainer>
        </Box>
    ));
};
