import { coreTheme } from "./../../Core/Components/Styles/CoreStyling";
import { createMuiTheme, Theme, ThemeOptions } from "@material-ui/core/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import { colors, Box } from "@material-ui/core";
import { Palette } from "@material-ui/core/styles/createPalette";
import styled from "styled-components";

//Define your custom breakpoints here or accept the default values which are the standard common breakpoints
let customBreakpointValues = {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
};
export const customBreakpoints = createBreakpoints({ values: customBreakpointValues });

//Add your specific app styling here
declare module "@material-ui/core" {
    export interface Theme {
        app: {
            colors: {
                success: {
                    dark: string;
                    light: string;
                };
            };
        };
    }
}

const getTheme = () => {
    let theme = createMuiTheme(coreTheme as ThemeOptions);
    return theme;
};

const appTheme = {
    app: {
        colors: {
            success: {
                dark: colors.green[600],
                light: colors.green[300],
            },
        },
    },
};

//Overrides
let theme = getTheme();
theme.breakpoints = customBreakpoints;
(theme.palette.text = {
    primary: "rgba(0,0,0, 1)",
    secondary: "rgba(0,0,0, 0.74)",
    disabled: "rgba(255,255,255, 0.38)",
    hint: "rgba(255,255,255, 0.38)",
}),
    // theme.palette = {
    //     common: { black: "#000", white: "#fff" },
    //     background: { paper: "#fff", default: "#fafafa" },
    //     primary: { light: "#7986cb", main: "#3f51b5", dark: "#303f9f", contrastText: "#fff" },
    //     secondary: { light: "#ff4081", main: "#f50057", dark: "#c51162", contrastText: "#fff" },
    //     error: { light: "#e57373", main: "#f44336", dark: "#d32f2f", contrastText: "#fff" },
    //     text: {
    //         secondary: "rgba(255,0,0, 1)",
    //         primary: "rgba(255,255,255, 0.54)",
    //         disabled: "rgba(255,255,255, 0.38)",
    //         hint: "rgba(255,255,255, 0.38)",
    //     },
    // } as Palette;

    (theme = { ...theme, ...appTheme });
export const defaultTheme = theme;
//(window as any).theme = defaultTheme;

export const LeftBox = styled(Box)`
    display: flex;
    padding: 60px 40px;
    height: 100%;

    > div.text-container {
        display: flex;
        flex-direction: column;
        align-self: flex-end;
        align-items: flex-start;

        h2 {
            font-weight: bold;
        }

        h2,
        h3 {
            color: #fff;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            > span {
                margin-bottom: 5px;
                //background-color: #145b79;
                display: block;
                padding: 5px 15px 10px;
            }
        }
    }
`;

interface IRightBoxProps {
    paddingBottomMobile: string;
}

export const RightBox = styled(Box)<IRightBoxProps>`
    background-color: rgba(255, 255, 255, 0.85);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    height: 100%;
    padding: 37px 30px;

    @media (max-width: 767px) {
        padding-top: 28px;
        padding-bottom: ${({ paddingBottomMobile }) => paddingBottomMobile};
    }

    .topright-link {
        right: 0;
        position: absolute;
        font-weight: bold;
    }

    h1.header-text {
        font-weight: bold;
        width: 100%;
        text-align: center;
        color: #333;
        margin: 30px 0 20px;

        @media (max-width: 767px) {
            margin-top: 10px;
        }

        &.align-left {
            text-align: left;
        }
    }

    button {
        margin-top: 28px;
        text-transform: none;
        border-radius: 10px;
        padding: 15px 0 14px;
        line-height: 150%;

        @media (max-width: 767px) {
            line-height: 120%;
        }
    }

    img.shoothill {
        width: 45%;
    }

    img.sct {
        width: 50%;
    }

    .link {
        text-decoration: underline;
        color: #0090df;
    }
`;
