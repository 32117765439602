import { Box, Button, Link, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { Link as RouterLink } from "react-router-dom";
import logoShoothill from "Custom/Content/Images/Shoothill-logo.svg";
import { StoresInstance, useObserver, useRouter } from "../../Core/Base";
import { HomeContainer } from "./Home.styles";
import { useMediaQuery } from "../../Core/Utils/Hooks/UseMediaQuery";
import { Covidcontainer } from "../StylesAppSpecific/HomeStyling";
import { LogoSwitcher } from "../Components/LogoSwitcher";

export const Home: React.FunctionComponent = () => {
    const [now] = useState(new Date());
    const { history, match } = useRouter();
    const isMobile = !!useMediaQuery("(max-width: 767px)");

    useEffect(() => {
        //let { layout } = match.params as any;
        if (history.location.pathname.indexOf("hbpro") > 0) {
            StoresInstance.domain.SetIsHBProSite(true);
        }
    }, []);

    const getHBPro = () => {
        return (
            <Box>
                <Box display="flex" height="100%" justifyContent="center" alignItems="center">
                    <HomeContainer>
                        <Box padding={isMobile ? 0 : "25px 10px"}>
                            <Typography component="p" variant="body1" className={`header-text ${isMobile ? "align-left" : ""}`}>
                                <strong>
                                    The Housebuilder Pro Covid-19 Site Tracker system is a free-to-use registration system designed to help businesses follow Government guidelines
                                    in logging visitors to sites and offices around the UK.
                                </strong>
                            </Typography>
                            <br />
                            <Typography component="p" variant="body1" className="paragraph">
                                Sign up takes less than 5 minutes and once you register your business, you will receive a unique QR code that you can print and display at your
                                business premises.
                            </Typography>
                            <br />
                            <Typography component="p" variant="body1" className="paragraph">
                                Your visitors or contractors can scan the code using their smart phones, which then takes them to a personalised registration page so you can keep
                                an accurate track of your visitors during these challenging times.
                            </Typography>
                            <br />
                            <Typography component="p" variant="body1" className="paragraph">
                                Once they have scanned the code, they will be presented with a "Sign in" or "Sign out" option so your visitor log is easily kept up-to-date.
                            </Typography>
                        </Box>
                        {!StoresInstance.domain.AccountStore.IsLoggedIn && (
                            <Box textAlign={"center"} width={isMobile ? undefined : "350px"} margin="auto" paddingTop={"50px"} paddingBottom={isMobile ? "47px" : "50px"}>
                                <Link component={RouterLink} className="button-link" to="/register">
                                    <Button type="submit" fullWidth variant="contained" color="secondary">
                                        <Box py={"10px"}>Sign up your business now</Box>
                                    </Button>
                                </Link>
                                <Box paddingTop={"20px"}>
                                    <Link component={RouterLink} className="button-link" to="/login">
                                        <Typography color={"secondary"} variant={"h6"}>
                                            <strong>Login</strong>
                                        </Typography>
                                    </Link>
                                </Box>
                            </Box>
                        )}
                        {StoresInstance.domain.AccountStore.IsLoggedIn && (
                            <Box width={isMobile ? undefined : "350px"} margin="auto" paddingBottom={isMobile ? "47px" : "50px"}>
                                <Link component={RouterLink} className="button-link" to="/dashboard">
                                    <Button type="submit" fullWidth variant="contained" color="secondary">
                                        Go to my dashboard
                                    </Button>
                                </Link>
                            </Box>
                        )}
                    </HomeContainer>
                </Box>
            </Box>
        );
    };

    const getCovidTracer = () => {
        return (
            <Box>
                <Box display="flex" height="100%" justifyContent="center" alignItems="center">
                    <HomeContainer>
                        <Box padding={isMobile ? 0 : "25px 10px"}>
                            <Typography component="h1" variant="h5" className={`header-text ${isMobile ? "align-left" : ""}`}>
                                What is <span className="header-sct">'Shoothill Covid Tracer'</span>?
                            </Typography>
                        </Box>
                        <Box padding={isMobile ? 0 : "25px 10px"}>
                            <Typography component="p" variant="body1" className="p1 paragraph">
                                The Shoothill Covid-19 trace system is a free-to-use visitor registration system designed to help businesses follow Government guidelines to help
                                support the NHS Test & Trace effort automatically.
                            </Typography>
                            <br />
                            <Typography component="p" variant="body1" className="paragraph">
                                Sign up takes less than 5 minutes and once you register your business, you will receive a unique QR code that you can print and place on your
                                business premises. Then your visitors / customer can scan the code using their smart phones, which then takes them to a personalised registration
                                page so you can keep an accurate track of your customers during these challenging times.
                            </Typography>
                        </Box>
                        {!StoresInstance.domain.AccountStore.IsLoggedIn && (
                            <Box textAlign={"center"} width={isMobile ? undefined : "350px"} margin="auto" paddingBottom={isMobile ? "47px" : "50px"}>
                                <Link component={RouterLink} className="button-link" to="/register">
                                    <Button type="submit" fullWidth variant="contained" color="secondary">
                                        Sign up your business now
                                    </Button>
                                </Link>
                                <Box paddingTop={"20px"}>
                                    <Link component={RouterLink} className="button-link" to="/login">
                                        <Typography color={"secondary"} variant={"h6"}>
                                            <strong>Login</strong>
                                        </Typography>
                                    </Link>
                                </Box>
                            </Box>
                        )}
                        {StoresInstance.domain.AccountStore.IsLoggedIn && (
                            <Box textAlign={"center"} width={isMobile ? undefined : "350px"} margin="auto" paddingBottom={isMobile ? "47px" : "50px"}>
                                <Link component={RouterLink} className="button-link" to="/dashboard">
                                    <Button type="submit" fullWidth variant="contained" color="secondary">
                                        Go to my dashboard
                                    </Button>
                                </Link>
                                <Box paddingTop={"20px"}>
                                    <Link component={RouterLink} className="button-link" to="/logout">
                                        <Typography color={"secondary"} variant={"h6"}>
                                            <strong>Logout</strong>
                                        </Typography>
                                    </Link>
                                </Box>
                            </Box>
                        )}
                        <Box width="55%" textAlign="center" margin="auto">
                            <img src={logoShoothill} alt="Shoothill Ltd." className="shoothill" />
                            <Typography variant="subtitle2">© {new Date().getFullYear()} Shoothill Ltd.</Typography>
                        </Box>
                    </HomeContainer>
                </Box>
            </Box>
        );
    };

    return useObserver(() => (
        <Box id={"maincontainer"} display={"flex"} height={"100%"} justifyContent={"center"} alignContent={"center"}>
            <Covidcontainer id={"covidcontainer"} className={"mobile"}>
                <LogoSwitcher />

                {StoresInstance.domain.isHbProSite ? getHBPro() : getCovidTracer()}
            </Covidcontainer>
        </Box>
    ));
};
