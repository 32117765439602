import { AccountStatus, ApiResult, ModelBase } from "../Models";
import { CheckHttpStatus, MergeDefaultConfig } from "../Utils/Axios";
import { FieldType, getHistory, getParentObjectPath } from "../Utils/Utils";
import { History, LocationState } from "history";
import { MetadataStorage, Validator, getFromContainer, validate, validateOrReject } from "class-validator";
import { ObjPathProxy, createProxy } from "ts-object-path";
import { action, computed, observable, runInAction } from "mobx";
import axios, * as Axios from "axios";

import { IModel } from "Core/Models/IModel";
import { IViewModel } from "Core/ViewModels/IViewModel";
import { ValidationMetadata } from "class-validator/metadata/ValidationMetadata";
import { get as _get } from "lodash-es";
import { isBoolean as _isBoolean } from "lodash-es";
import { isString as _isString } from "lodash-es";
import { set as _set } from "lodash-es";
import { createViewModel } from "mobx-utils";
import dot from "dot-object";
import { match } from "react-router";
import { Stores, StoresInstance } from "../../Custom/Stores";
import { constants } from "os";
//import { StoresInstance } from "../../Custom/Stores";

//Give typing and intellisense to the field names

export type ValidationResponse = {
    isValid: boolean;
    errorMessage: string;
};
export type Create<T> = new (...args: any[]) => T;
export abstract class ViewModelBase<T extends IModel<T> = any> implements IViewModel<T> {
    public model: T = {} as T;

    @observable public IsLoading: boolean = false;
    @observable public IsErrored = false;
    @observable public Errors: string = "";
    @observable public Valid: boolean = false;

    @action protected setIsLoading = (state: boolean) => (this.IsLoading = state);
    @action protected setIsErrored = (state: boolean) => (this.IsErrored = state);
    @action protected setErrors = (state: string) => (this.Errors = state);

    public history: History;
    public location: LocationState = {} as LocationState;
    public match = {} as match<{}>;

    public validatorStorage: MetadataStorage = getFromContainer(MetadataStorage);
    private meta = {} as ValidationMetadata[];
    private validator = new Validator();
    private proxy: T = {} as T;

    protected constructor(model: T, undoable: boolean = false, enableProxy: boolean = true) {
        this.history = getHistory();

        if (model) {
            if (enableProxy) {
                this.createNewProxy(model, undoable);
            } else {
                this.internalSetModel(model, undoable);
            }
        }
        (window as any).model = model;
    }

    public createNewProxy = (model: T, undoable: boolean) => {
        let self: IViewModel<T> = this;
        this.proxy = new Proxy(model, {
            get(target: any, value: any, receiver: any) {
                let val = Reflect.get(target, value, receiver); // (1)
                return typeof value == "function" ? val.bind(target) : val;
            },
            set(target: any, prop: any, value: any, receiver: any) {
                let newValue = value;
                if (typeof self["beforeUpdate"] === "function") {
                    let tmpValue = self["beforeUpdate"](prop, value);
                    if (tmpValue !== null && tmpValue !== undefined) {
                        newValue = tmpValue;
                    }
                }
                let retval = Reflect.set(target, prop, newValue, receiver); // (1)
                if (typeof self["afterUpdate"] === "function") {
                    self["afterUpdate"](prop, newValue);
                }
                return retval;
            },
        });
        this.internalSetModel(this.proxy, undoable);
    };

    private getType = <T>(TCtor: new (...args: any[]) => T) => {
        return typeof TCtor;
    };

    //This must be overriden in any class that extends this base class
    abstract isFieldValid(fieldName: keyof FieldType<T>, value: any): boolean;
    abstract beforeUpdate?(fieldName: keyof FieldType<T>, value: any): any;
    abstract afterUpdate?(fieldName: keyof FieldType<T>, value: any): void;

    @computed
    public get getModel(): T {
        return this.model;
    }
    @computed
    public get screenWidth(): number {
        return StoresInstance.coreStore.screenWidth;
    }
    @computed
    public get isMobile(): boolean {
        return StoresInstance.coreStore.isMobile;
    }
    @computed
    public get isTablet(): boolean {
        return StoresInstance.coreStore.isTablet;
    }
    @computed
    public get isDesktop(): boolean {
        return StoresInstance.coreStore.isDesktop;
    }
    @computed
    public get isLoggedIn(): boolean {
        return StoresInstance.domain.AccountStore.IsLoggedIn;
    }
    private internalSetModel(model: T, undoable: boolean = false) {
        if (undoable) {
            //This is a helper method to make the model undoable. You must call submit on the model to save changes
            this.model = createViewModel(model);
            return;
        }
        this.model = model;
    }

    public setModel(model: T, undoable: boolean = false) {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this.getValue(key as any) instanceof Date) {
                    this.setValue(key as any, new Date(model[key] as any));
                } else {
                    this.setValue(key as any, model[key]);
                }
            }
        }
    }

    public getContext = (): ObjPathProxy<T, T> => {
        return createProxy<T>();
    };

    public saveModel(): void {
        (this.model as any).submit();
    }

    public resetModel(): void {
        (this.model as any).reset();
    }

    @action
    public setValue<TR>(fieldName: keyof FieldType<T>, value: TR) {
        this.model.setValue<TR>(fieldName, value);
    }

    public getValue<TR>(fieldName: keyof FieldType<T>): TR {
        let value = this.model.getValue<TR>(fieldName);
        if (value === null) {
            if (_isString(value)) {
                ((value as any) as string) = "";
            } else if (_isBoolean(value)) {
                ((value as any) as boolean) = false;
            }
            this.model.setValue(fieldName, value);
        }
        return value;
    }

    @action
    public setError(fieldName: keyof FieldType<T> | string, value: string) {
        this.model.setError(fieldName, value);
    }

    public getError(fieldName: keyof FieldType<T> | string) {
        return this.model.getError(fieldName);
    }

    @action
    public setValid(fieldName: keyof FieldType<T> | string, value: boolean): void {
        this.model.setValid(fieldName, value);
    }

    public getValid(fieldName: keyof FieldType<T> | string): boolean {
        return this.model.getValid(fieldName);
    }

    @action
    public setDirty(fieldName: keyof FieldType<T> | string, value: boolean): void {
        this.model.setDirty(fieldName, value);
    }

    public getDirty(fieldName: keyof FieldType<T> | string): boolean {
        return this.model.getDirty(fieldName);
    }

    @action
    public setTouched(fieldName: keyof FieldType<T> | string, value: boolean): void {
        this.model.setTouched(fieldName, value);
    }

    public getTouched(fieldName: keyof FieldType<T> | string): boolean {
        return this.model.getTouched(fieldName);
    }

    public isModelValid = () => {
        let valid = true;
        //EN: Flatten the object into dot notation so we can iterate over nested objects
        let target = dot.dot(this.model);
        for (let prop in target) {
            if (prop.indexOf("Errors.") < 0 && prop.indexOf("Dirty.") < 0 && prop.indexOf("Touched.") < 0 && prop.indexOf("Valid.") < 0) {
                if (prop != "getParentObjectPath") {
                    this["isFieldValid"](prop as any, _get(this.model, prop));
                }
            }
        }

        // //Run through again checking properties of model
        for (let prop in target) {
            if (prop.indexOf("Errors.") < 0 && prop.indexOf("Dirty.") < 0 && prop.indexOf("Touched.") < 0 && prop.indexOf("Valid.") < 0) {
                if (valid) {
                    let path = getParentObjectPath(prop, "Valid");
                    valid = _get(this.model, path);
                }
            }
        }
        runInAction(() => {
            this.Valid = valid;
        });
        return valid;
    };

    private parseObjectProperties = (obj: any, parse: any) => {
        for (let k in obj) {
            if (typeof obj[k] === "object" && obj[k] !== null) {
                this.parseObjectProperties(obj[k], parse);
            } else if (obj.hasOwnProperty(k)) {
                parse(obj, k);
            }
        }
    };

    public setDecorators = (model: any) => {
        this.meta = this.validatorStorage.getTargetValidationMetadatas(model, "");
    };

    public validateDecorators = (fieldName: keyof FieldType<T>): ValidationResponse => {
        let target = this.meta.filter((a) => a.propertyName === fieldName).reverse();
        let message = "";
        if (target && target.length > 0) {
            let validated = false;
            target.some((t: ValidationMetadata) => {
                validated = this.validator.validateValueByMetadata(this.getValue(fieldName), t!);
                if (t.message) {
                    message = t.message.toString();
                } else if (t.constraints.length > 0) {
                    message = t.constraints[0].message;
                }
                return !validated;
            });
            //let vp = this.validator.length("", 1, 10);
            //let ve = new ValidationExecutor(this.validator);
            //let promise = await validate(target!);
            return { isValid: validated, errorMessage: validated ? "" : message.toString() };
        } else {
            //No decorators found so presume no validation required
            return { isValid: true, errorMessage: "" };
        }
    };

    public getModelAsPayload(): T {
        let payload = this.getAnyModelAsPayload(this.model);
        return payload;
    }

    public getAnyModelAsPayload(model: any): T {
        let exclude = ["Dirty", "Errors", "Valid", "Touched", "localComputedValues", "localValues", "isPropertyDirty"];
        let payload = {} as T;
        for (let key in this.model) {
            if (this.model.hasOwnProperty(key)) {
                if (!exclude.includes(key)) {
                    //EN: Check for recursed models
                    if (key == "model" && typeof this.model[key] === "object") {
                        continue;
                    }
                    payload[key] = this.model[key];
                    if (typeof payload[key] === "string") {
                        //EN: Exclude null characters in a string
                        ((payload[key] as any) as string).replace(/\0/g, "");
                    }
                }
            }
        }
        return payload;
    }

    Get = <TPayload = ApiResult<undefined>>(
        url: string,
        //model?: any,
        config?: Axios.AxiosRequestConfig,
    ): Promise<ApiResult<TPayload>> => {
        this.setIsLoading(true);
        const getPromise = axios
            .get<ApiResult<TPayload>>(url, this.getConfig(config))
            .then(async (response) => {
                if (response.headers["token-expired"]) {
                    let newTokenResult = await axios.post<ApiResult<any>>("/api/account/refresh", {
                        accessToken: localStorage.getItem(".auth"),
                    });
                    this.setLoginState(newTokenResult.data.payload);
                    if (newTokenResult.data.payload.jwt === "") {
                        //Go to session expired page
                        window.location.href = "/sessionexpired";
                        //return false;
                    }
                    //Make the original call again
                    response = await axios.get<ApiResult<TPayload>>(url, this.getConfig(config));
                } else if (response.headers["unauthorized"]) {
                    this.logout();
                }
                //CheckHttpStatus(response);
                this.setIsLoading(false);
                return response.data;
            })
            .catch((error) => {
                this.setIsErrored(true);
                this.setIsLoading(false);
                this.setErrors(error);
                if (error.httpStatusCode === 401) {
                    this.logout();
                    return;
                }
                return { wasSuccessful: false };
            });

        return getPromise as Promise<ApiResult<TPayload>>;
    };

    Post = <TPayload = ApiResult<undefined>>(url: string, model?: any, config?: Axios.AxiosRequestConfig): Promise<ApiResult<TPayload>> => {
        this.setIsLoading(true);
        const postPromise = axios
            .post<ApiResult<TPayload>>(url, model, this.getConfig(config))
            .then(async (response) => {
                if (response.headers["token-expired"]) {
                    let newTokenResult = await axios.post<ApiResult<any>>("/api/account/refresh", {
                        accessToken: localStorage.getItem(".auth"),
                    });
                    this.setLoginState(newTokenResult.data.payload);
                    if (newTokenResult.data.payload.jwt === "") {
                        //Go to session expired page
                        window.location.href = "/sessionexpired";
                    }
                    response = await axios.post<ApiResult<TPayload>>(url, model, this.getConfig(config));
                } else if (response.headers["unauthorized"]) {
                    this.logout();
                }
                //CheckHttpStatus(response);
                this.setIsLoading(false);

                return response.data;
            })
            .catch((error) => {
                this.setIsErrored(true);
                this.setIsLoading(false);
                this.setErrors(error);
                if (error.httpStatusCode === 401) {
                    this.logout();
                    return;
                }
                return { wasSuccessful: false };
            });

        return postPromise as Promise<ApiResult<TPayload>>;
    };

    setLoginState = (apiResult: AccountStatus) => {
        localStorage.setItem(".auth", apiResult.jwt as string);
        //EN: Hack at the moment so that we do not get cicular references
        // let stores = (window as any).Stores as Stores;
        // if (stores) {
        // 	stores.domain.AccountStore.getLoginState(apiResult);
        // }
    };
    logout = () => {
        //(window as any).Stores.domain.AccountStore.Logout();
        this.history.push("/logout");
    };
    getConfig = (config?: Axios.AxiosRequestConfig) => {
        const requestConfig = MergeDefaultConfig(config);
        //Sets the bearer on every header if available
        //Note: You might need to remove this bearer if calling 3rd party api's
        let jwt = localStorage.getItem(".auth") as string;
        if (jwt && jwt.length > 0) {
            requestConfig.headers = {
                Authorization: "Bearer " + jwt,
                "content-type": "application/json",
            };
        } else {
            requestConfig.headers = {
                "content-type": "application/json",
            };
        }
        requestConfig.validateStatus = (status: number) => {
            return status < 500;
        };
        return requestConfig;
    };
}
