import * as MobX from "mobx";

import { IsEmail, IsNotEmpty } from "class-validator";

import { ModelBase } from "../../Core/Models/ModelBase";

export class ForgotPasswordModel extends ModelBase<ForgotPasswordModel, ForgotPasswordModelDTO> {
    @MobX.observable
    @IsNotEmpty({ message: "You must enter an email address" })
    @IsEmail({}, { message: "Email address is not in a valid format" })
    public emailAddress: string = "";

    fromDto(model: ForgotPasswordModelDTO): void {}

    toDto(model: ForgotPasswordModel): void {}
}

export interface ForgotPasswordModelDTO {
    emailAddress: string;
}
