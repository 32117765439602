import { ModelBase } from "Core/Models/ModelBase";
import * as MobX from "mobx";
import { validate, validateOrReject, Contains, IsInt, Length, IsEmail, IsFQDN, IsDate, Min, Max, IsNotEmpty, ValidateIf, Equals } from "class-validator";
import { RegisterAddressModel } from "./RegisterUserAddressModel";

export class CovidRegModel extends ModelBase<CovidRegModel, CovidRegModel> {
    @MobX.observable
    public userId: string = "";

    @MobX.observable
    @IsNotEmpty({ message: "First name is required" })
    public firstName: string = "";

    @MobX.observable
    public dateRegistered: Date | null = null;

    @MobX.observable
    @IsNotEmpty({ message: "Last name is required" })
    public lastName: string = "";

    @MobX.observable
    public company: string = "";

    @MobX.observable
    @IsNotEmpty({ message: "Phone number is required" })
    public phoneNumber: string = "";

    @MobX.observable
    //@IsNotEmpty({ message: "Email address is required" })
    //@IsEmail({}, { message: "Email address is not correct" })
    public emailAddress: string = "";

    @MobX.observable
    //@IsNotEmpty({ message: "Postcode is required" })
    public postCode: string = "";

    @MobX.observable
    @Equals(true, { message: "Must be ticked" })
    public agreedConsent: boolean = false;

    public signIn: boolean = false;
    public signOut: boolean = false;

    public fromDto = (model: CovidRegModel) => {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    };
    public toDto = (model: CovidRegModel) => {};
}
