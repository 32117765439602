// Libs

import * as MobX from "mobx";

import { IsEmail } from "class-validator";
import { ModelBase } from "../../Core/Models/ModelBase";
import { Role } from ".";

// App

export class User extends ModelBase<User, UserDTO> {
    public id: string = "";
    @MobX.observable public firstName: string = "";
    @MobX.observable public lastName: string = "";
    @MobX.observable
    @IsEmail({}, { message: "Email is not valid" })
    public email: string = "";
    @MobX.observable public password: string = "";
    @MobX.observable public role: string = "";
    @MobX.observable public concurrencyToken: string = "";
    @MobX.observable public isDeleted: boolean = false;
    @MobX.observable public userRoles = MobX.observable<Role>([]);

    constructor(id: string) {
        super();
        this.id = id;
    }

    @MobX.action
    public fromDto = (model: UserDTO) => {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                this[key] = model[key];
            }
        }
        // this.id = model.id;
        // this.firstName = model.firstName;
        // this.lastName = model.lastName;
        // this.email = model.email;
        // this.password = model.password;
        // this.role = model.role;
        // this.concurrencyToken = model.concurrencyToken;
        // this.isDeleted = model.isDeleted;
    };

    public toDto = (model: UserDTO) => {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                model[key] = this[key];
            }
        }
    };
}

export interface UserDTO {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    role: string;
    concurrencyToken: string;
    isDeleted: boolean;
}
