import { Stores, StoresInstance } from "../Stores";
import HBPLogo from "../Content/Images/hbprologo.png";
import SCTLogo from "../Content/Images/sctlogo.png";
import { Box, Typography } from "@material-ui/core";
import React from "react";
import { useObserver } from "mobx-react-lite";
import { Show } from "../../Core/Base";
import siteTracker from "../Content/Images/sitetracker.png";
import { AutoGrid } from "../../Core/Components/AutoGrid";

interface Props {
    displayName?: string;
}

export const LogoSwitcher: React.FC<Props> = (props: Props) => {
    const getLogo = () => {
        if (props.displayName && props.displayName !== "") {
            let url = `https://shoothillweb2general.blob.core.windows.net/covidtracer/logos/${props.displayName}_thumb.jpg`;
            return <img style={{ height: "60px" }} id={"logo"} src={url} alt={"Company Logo"} />;
        } else if (StoresInstance.domain.isHbProSite) {
            return (
                <img
                    onClick={() => (window.location.href = "https://housebuilderpro.co.uk")}
                    style={{ height: "60px", cursor: "pointer" }}
                    id={"logo"}
                    src={HBPLogo}
                    alt={"Company Logo"}
                />
            );
        } else {
            return <img crossOrigin={"anonymous"} style={{ height: "50px" }} id={"logo"} src={SCTLogo} alt={"Company Logo"} />;
        }
    };

    return useObserver(() => (
        <AutoGrid desktopColumns={"1fr 1fr"} mobileColumns={"1fr"} alignItems={"center"} justifyContent={"space-between"}>
            {getLogo()}
            <Show iff={StoresInstance.domain.isHbProSite}>
                <Box pt={2} textAlign={!StoresInstance.coreStore.isMobile && "right"}>
                    <img width={"100px"} src={siteTracker} />
                </Box>
                {/*<Typography variant={StoresInstance.coreStore.isMobile ? "caption" : "h6"}>
                    <strong>Housebuilder Pro Site Tracker</strong>
                </Typography>*/}
            </Show>
        </AutoGrid>
    ));

    /*return useObserver(() => (
        <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} pb={1}>
            {getLogo()}
            <Show iff={StoresInstance.domain.isHbProSite}>
                <img width={"100px"} src={siteTracker} />
                {/!*<Typography variant={StoresInstance.coreStore.isMobile ? "caption" : "h6"}>
                    <strong>Housebuilder Pro Site Tracker</strong>
                </Typography>*!/}
            </Show>
        </Box>
    ));*/
};
