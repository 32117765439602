import React, { useContext } from "react";
import { Stores, StoresContext } from "./Custom/Stores";

import { Admin } from "./Custom/Views/Admin/Admin";
import { ForgotPasswordPage } from "./Custom/Views/ForgotPasswordPage";
import { Samples } from "./Custom/Views/Samples";
import { LogViewer } from "./Core/Views/LogViewer";
import { LoginPage } from "./Custom/Views/LoginPage";
import { PrivateRoute } from "Core/Utils";
import { RegisterPage } from "./Custom/Views/Register/RegisterPage";
import { ResetPasswordPage } from "./Custom/Views/ResetPasswordPage";
import { RoleDetails } from "./Custom/Views/Admin/RoleDetails";
import { Route } from "react-router";
import { SessionExpired } from "./Custom/Views/SessionExpired";
import { TestPage } from "./Core/Views/TestPage";
import { User } from "./Custom/Views/User";
import { UserDetails } from "./Custom/Views/Admin/UserDetails";
import { Logout } from "Custom/Views/Logout";
import { Home } from "./Custom/Views/Home";
import { Error } from "./Core/Views/Error";
import { Error2 } from "./Core/Views/Error2";
import { NotFound } from "./Core/Views/NotFound";
import { AdminRoutes } from "./Custom/Views/Admin/AdminRoutes";
import { QR } from "./Custom/Views/QR";
import { CovidRegPage } from "./Custom/Views/CovidRegPage";
import { Registrations } from "./Custom/Views/Registrations";

export const AppRoutes: React.FC = () => {
    const store = useContext<Stores>(StoresContext);
    return (
        <>
            <Route exact path="/" component={Home} />
            <Route exact path="/trace/:displayName?" component={CovidRegPage} />
            <Route exact path="/samples" component={Samples} />
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/logout" component={Logout} />
            <Route exact path="/forgotpassword" component={ForgotPasswordPage} />
            <Route exact path="/resetpassword" component={ResetPasswordPage} />
            <Route exact path="/register" component={RegisterPage} />
            <Route exact path="/dashboard/:subpage?" component={RegisterPage} />
            <Route exact path="/sessionexpired" component={SessionExpired} />
            <Route exact path="/logviewer" component={LogViewer} />
            <Route exact path="/error" component={Error2} />
            <Route exact path="/notfound" component={NotFound} />
            <Route exact path="/registrations" component={Registrations} />
            <Route exact path="/qr/:displayName" component={QR} />
            <AdminRoutes />
        </>
    );
};
