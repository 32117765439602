import * as MobX from "mobx";
import { ModelBase } from "Core/Models/ModelBase";
import { IsEmail, IsNotEmpty } from "class-validator";
import { IObservable } from "mobx";

export class Summary extends ModelBase<Summary, Summary> {
    @MobX.observable
    public userId: string = "";
    @MobX.observable
    public tradingName: string = "";
    @MobX.observable
    public displayName: string = "";
    @MobX.observable
    public bitly: string = "";
    @MobX.observable
    public isHBPro: boolean = false;

    fromDto(model: Summary): void {}
    toDto(model: Summary): void {}
}

export interface LoginModelDTO {
    emailAddress: string;
    password: string;
    rememberMe: boolean;
}
