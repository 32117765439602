import { Box, Button, Link, Typography } from "@material-ui/core";
import React, { useState } from "react";

import { EditableInput } from "Core/Components/EditableInput";
import { LoginViewModel } from "../ViewModels/LoginViewModel";
import { Redirect } from "react-router";
import { CSSGrid, Cell } from "../../Core/Components/Styles";
import { LoginContainer } from "./LoginPage.styles";
import { Link as RouterLink } from "react-router-dom";
import logoShoothill from "Custom/Content/Images/Shoothill-logo.svg";
import logoSCT from "Custom/Content/Images/SCT-logo.svg";
import { StoresInstance, useObserver } from "../../Core/Base";
import { LeftBox, RightBox } from "Custom/StylesAppSpecific/AppStyling";
import { useMediaQuery } from "../../Core/Utils/Hooks/UseMediaQuery";
import { Covidcontainer } from "../StylesAppSpecific/HomeStyling";
import { LogoSwitcher } from "../Components/LogoSwitcher";

export const LoginPage: React.FunctionComponent = () => {
    const [viewModel] = useState(() => new LoginViewModel());
    const [now] = useState(new Date());
    const isMobile = !!useMediaQuery("(max-width: 767px)");

    if (viewModel.isLoggedIn) {
        return <Redirect to={"/dashboard"} />;
    }

    return useObserver(() => (
        <Box id={"maincontainer"} display={"flex"} height={"100%"} justifyContent={"center"} alignContent={"center"}>
            <Covidcontainer id={"covidcontainer"}>
                <LogoSwitcher />
                <form onSubmit={viewModel.handleSubmit}>
                    <Box pb={"25px"} display="flex" height="100%">
                        <Typography variant="h5" className="header-text">
                            Company Login
                        </Typography>
                    </Box>
                    <Box>
                        {viewModel.errorMessage !== "" && (
                            <Typography variant="caption" style={{ color: "red" }}>
                                {viewModel.errorMessage}
                            </Typography>
                        )}
                    </Box>
                    <CSSGrid columns={viewModel.isMobile ? "1fr" : "1fr 1fr"} columnGap={"50px"} rowGap={"50px"}>
                        <Box>
                            <EditableInput
                                type="email"
                                label="Username"
                                viewModel={viewModel}
                                fieldName="emailAddress"
                                inputProps={{ placeholder: "Your display name or email address", InputLabelProps: { shrink: true } }}
                            />
                            <Typography variant="body2" color="textSecondary" className="login-text under-input">
                                Login using your contact email address
                            </Typography>
                        </Box>
                        <Box>
                            <EditableInput
                                className="password-field"
                                type="password"
                                label="Password"
                                viewModel={viewModel}
                                fieldName="password"
                                inputProps={{ placeholder: "Your password", InputLabelProps: { shrink: true } }}
                            />
                            <Link component={RouterLink} className="link under-input" to="/forgotpassword">
                                <Typography color={"secondary"} variant={"body2"}>
                                    I've forgotten my password
                                </Typography>
                            </Link>
                        </Box>
                        <Cell width={viewModel.isMobile ? 1 : 2}>
                            <Box textAlign={"center"}>
                                <Button style={{ width: "350px" }} type="submit" fullWidth variant="contained" color="secondary" disabled={viewModel.IsLoading}>
                                    {viewModel.IsLoading ? "Please Wait..." : "Login"}
                                </Button>
                            </Box>
                            <Box pt={1} textAlign={"center"}>
                                <Link component={RouterLink} className="link under-input" to="/">
                                    <Typography color={"secondary"} variant={"h6"}>
                                        Home
                                    </Typography>
                                </Link>
                            </Box>
                        </Cell>
                        {!StoresInstance.domain.isHbProSite && (
                            <Cell width={viewModel.isMobile ? 1 : 2}>
                                <Box textAlign={"center"}>
                                    <img src={logoShoothill} alt="Shoothill Ltd." className="shoothill" />
                                    <Typography variant="subtitle2">© {now.getFullYear()} Shoothill Ltd.</Typography>
                                </Box>
                            </Cell>
                        )}
                    </CSSGrid>
                </form>
            </Covidcontainer>
        </Box>
    ));
};
