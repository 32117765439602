import React, { useState, useContext, useRef, useEffect } from "react";
import { Link, Box, useMediaQuery } from "@material-ui/core";
import { LinkBox, RightSide, Dot, DotWrapper } from "Core/Components/Styles/BaseStyles";
import { Animation } from "Core/Examples/Animation";
import { Buttons } from "Core/Examples/Buttons";
import { DatePicker } from "Core/Examples/DatePicker";
import { GridLayouts } from "Core/Examples/GridLayouts";
import { SelectInput } from "Core/Examples/SelectInput";
import { Text } from "Core/Examples/Text";
import { TextInputs } from "Core/Examples/TextInputs";
import { Layouts } from "Core/Examples/Layouts";
import { CSSGrid, Cell } from "Core/Components/Styles";

export const Samples: React.FC = () => {
    const desktopScreen = useMediaQuery("(min-width:600px)");
    const [view, setView] = useState(<Text />);

    return (
        <CSSGrid columns={desktopScreen ? "1fr 5fr" : "1fr"}>
            <div>
                <LinkBox>
                    <Link onClick={() => setView(<Text />)}>Typography</Link>
                </LinkBox>
                <LinkBox>
                    <Link onClick={() => setView(<Buttons />)}>Buttons</Link>
                </LinkBox>
                <LinkBox>
                    <Link onClick={() => setView(<TextInputs />)}>Text Input</Link>
                </LinkBox>
                <LinkBox>
                    <Link onClick={() => setView(<SelectInput />)}>Select Input</Link>
                </LinkBox>
                <LinkBox>
                    <Link onClick={() => setView(<DatePicker />)}>Date Picker</Link>
                </LinkBox>
                <LinkBox>
                    <Link onClick={() => setView(<GridLayouts />)}>Grid Layouts</Link>
                </LinkBox>
                <LinkBox>
                    <Link onClick={() => setView(<Layouts />)}>Layouts</Link>
                </LinkBox>
                <LinkBox>
                    <Link onClick={() => setView(<Animation />)}>Animation</Link>
                </LinkBox>
                <LinkBox>
                    <Link onClick={() => setView(<Loaders />)}>Loaders</Link>
                </LinkBox>
            </div>
            <Box>{view}</Box>
        </CSSGrid>
    );
};

export const Loaders: React.FC = () => {
    return (
        <>
            <Box paddingTop={2}>
                Animated Dots
                <DotWrapper>
                    <Dot delay="0s" />
                    <Dot delay=".1s" />
                    <Dot delay=".2s" />
                </DotWrapper>
            </Box>
        </>
    );
};
