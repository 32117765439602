import moment from "moment-timezone";

export const dateTimeFormat = "DD/MM/YYYY";
export const FormatMoment = (momentDateTime: moment.Moment, format: string = dateTimeFormat) => {
    if (!momentDateTime.isValid()) {
        return "";
    }

    return momentDateTime.format(format);
};
export const FormatDate = (momentDateTime: Date | null, format: string = dateTimeFormat) => {
    (window as any).moment = moment;
    if (typeof momentDateTime === "string") {
        if (!(momentDateTime as string).endsWith("Z")) {
            (momentDateTime as string) += "Z";
        }
    }
    return moment(momentDateTime).tz("Europe/London").format(format);
};
export const FormatMomentString = (rawDateTime: string | undefined, format: string = dateTimeFormat) => {
    if (!rawDateTime) {
        return "";
    }

    const momentDateTime = moment.utc(rawDateTime);

    return FormatMoment(momentDateTime, format);
};

export const dateTimeString = (rawDateTime: string | undefined) => FormatMomentString(rawDateTime, dateTimeFormat);
