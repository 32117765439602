import { StoresInstance } from "../Stores";
import { Box, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useObserver } from "mobx-react-lite";
import HBPLogo from "../Content/Images/hbprologo2.png";
import SCTLogo from "../Content/Images/sctlogo.png";
import ShoothillLogo from "../Content/Images/shoothilllogo.png";

interface Props {
    displayName?: string;
}

export const FooterSwitcher: React.FC<Props> = (props: Props) => {
    const [now] = useState(new Date());

    const getLogo = () => {
        if (StoresInstance.domain.isHbProSite) {
            return <img className="shoothill" id={"logo"} src={HBPLogo} alt={"Company Logo"} />;
        } else {
            return <img className="shoothill" id={"logo"} src={ShoothillLogo} alt={"Company Logo"} />;
        }
    };

    const getText = () => {
        if (StoresInstance.domain.isHbProSite) {
            return <strong>© {now.getFullYear()} Housebuilder Pro Ltd.</strong>;
        } else {
            return <strong>© {now.getFullYear()} Shoothill Ltd.</strong>;
        }
    };

    return useObserver(() => (
        <Box display={"flex"} alignItems={"center"} justifyContent={"center"} pt={5} pb={5}>
            <Box textAlign={"center"}>
                {getLogo()}
                <Box pt={3}>
                    <Typography variant="body1">{getText()}</Typography>
                </Box>
            </Box>
        </Box>
    ));
};
