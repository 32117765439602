import { useEffect, useState } from "react";

export const useMediaQuery = (useQuery: string) => {
    const query = window.matchMedia(useQuery);
    const [match, setMatch] = useState(query.matches);
    useEffect(() => {
        const handleMatch = (mediaQuery: any) => setMatch(mediaQuery.matches);
        query.addListener(handleMatch);
        return () => query.removeListener(handleMatch);
    });
    return match ? true : false;
};
