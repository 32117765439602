import * as moment from "moment";

import { Box, Checkbox, FormControl, FormControlLabel, FormHelperText, InputLabel, MenuItem, Radio, TextField } from "@material-ui/core";
import { FieldType, generateID } from "../Utils/Utils";
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ObjPathProxy, getPath } from "ts-object-path";
import React, { useEffect, useRef, useState } from "react";

import FormLabel from "@material-ui/core/FormLabel";
import { IModel } from "Core/Models/IModel";
import { IViewModel } from "Core/ViewModels/IViewModel";
import { KeyValuePair } from "Core/Models/KeyValuePair";
import { Moment } from "moment";
import MomentUtils from "@date-io/moment";
import RadioGroup from "@material-ui/core/RadioGroup";
import Select from "@material-ui/core/Select";
import { Validation } from "./Validation";
import styled from "styled-components";
import { useObserver } from "mobx-react-lite";

const Control = styled(FormControl)`
    min-width: 120px;
`;

type TModel<T> = IModel<T> & any;

type InputProps = {
    multiline?: boolean;
    rows?: number;
    maxLength?: number;
    renderValue?: (selected: any) => JSX.Element;
    placeholder?: string;
    InputLabelProps?: {
        shrink?: boolean;
    };
};

export type EditableInputProps<T> = {
    viewModel: IViewModel<T>;
    fieldName: keyof FieldType<T> | string;
    selectItems?: KeyValuePair[];
    inputProps?: InputProps;
    required?: boolean;
    editMode?: boolean;
    validateOnStart?: boolean;
    validateOnBlur?: boolean;
    fullwidth?: boolean;
    autoComplete?: string;
    alphaOnly?: boolean;
    type?: "text" | "number" | "password" | "email" | "checkbox" | "radio" | "select" | "date" | "time" | "datetime" | "multiselect";
    label?: string | JSX.Element;
    className?: string;
    //[x: string]: IViewModel | undefined | string | boolean | InputProps | KeyValuePair[];
};

export function EditableInput<T>(props: EditableInputProps<T>) {
    const rendered = useRef<boolean>(false);
    const { multiline } = props.inputProps!;
    const [bind, { text, password, radio, checkbox, date, time, email, select, number, multiselect }] = Validation<T>(props.viewModel);

    let fieldName: keyof FieldType<T> = props.fieldName as any;
    if (typeof props.fieldName !== "string") {
        let p = getPath(fieldName as string);
        fieldName = p.join(".") as any;
    }

    useEffect(() => {
        rendered.current = true;

        if (props.validateOnStart) {
            validate(props.viewModel.getValue(fieldName));
        }
    }, []);

    const validate = (value: any) => {
        props.viewModel.isFieldValid(fieldName, value);
    };

    const isInError = (): boolean => {
        let isValid = props.viewModel.getValid(fieldName);
        if (props.validateOnStart) {
            return !isValid;
        }

        if (!rendered.current) {
            return false;
        }
        return !isValid;
    };

    const renderControl = () => {
        if (props.type === "checkbox") {
            return checkBox();
        } else if (props.type === "radio") {
            return radiocomp();
        } else if (props.type === "select" || props.type === "multiselect") {
            return selectField();
        } else if (props.type === "date") {
            return dateComp();
        } else if (props.type === "time") {
            return timeComp();
        } else {
            return textField();
        }
    };

    const dateComp = () => {
        return (
            <>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                        placeholder="01/JAN/2020"
                        label={props.label}
                        {...date(fieldName, { validateOnBlur: props.validateOnBlur })}
                        mask={"__-__-____"}
                        format="DD-MMM-YYYY"
                        className={props.className}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </MuiPickersUtilsProvider>
            </>
        );
    };

    const timeComp = () => {
        return (
            <>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardTimePicker
                        placeholder="08:00 AM"
                        label={props.label}
                        mask={"__:__ _M"}
                        {...time(fieldName, { validateOnBlur: props.validateOnBlur })}
                        className={props.className}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </MuiPickersUtilsProvider>
            </>
        );
    };

    const radiocomp = () => {
        return (
            <Control error={isInError()}>
                <FormLabel component="legend" id="demo-simple-radio-label">
                    {props.label}
                </FormLabel>
                <RadioGroup
                    {...radio(fieldName, { validateOnBlur: props.validateOnBlur })}
                    row={true}
                    aria-label={props.label as string}
                    name={props.label as string}
                    className={props.className}
                >
                    {props.selectItems!.map((item: KeyValuePair) => {
                        return <FormControlLabel key={generateID()} value={item.value} name={props.label as string} control={<Radio />} label={item.key} />;
                    })}
                </RadioGroup>
                {isInError() && <FormHelperText style={{ color: "red" }}>{props.viewModel.getError(fieldName)}</FormHelperText>}
            </Control>
        );
    };

    const selectField = () => {
        return (
            <Control error={isInError()}>
                <InputLabel id="demo-simple-select-label">{props.label}</InputLabel>
                <Select
                    MenuProps={{
                        disableScrollLock: true,
                    }}
                    {...(props.type === "multiselect"
                        ? multiselect(fieldName, { validateOnBlur: props.validateOnBlur })
                        : select(fieldName, { validateOnBlur: props.validateOnBlur }))}
                    {...(props.inputProps || {})}
                    className={props.className}
                >
                    {props.selectItems!.map((item: KeyValuePair, index: number) => {
                        return (
                            <MenuItem key={generateID()} value={item.value}>
                                {item.key}
                            </MenuItem>
                        );
                    })}
                </Select>
                {isInError() && <FormHelperText style={{ color: "red" }}>{props.viewModel.getError(fieldName)}</FormHelperText>}
            </Control>
        );
    };

    const getTypeOfInput = () => {
        if (props.type === "number") {
            return number(fieldName, { validateOnBlur: props.validateOnBlur });
        } else if (props.type === "password") {
            return password(fieldName, { validateOnBlur: props.validateOnBlur });
        } else if (props.type === "email") {
            return email(fieldName, { validateOnBlur: props.validateOnBlur });
        }
        return text(fieldName, { validateOnBlur: props.validateOnBlur, alphaOnly: props.alphaOnly });
    };
    const textField = () => {
        if (props.editMode) {
            return (
                <TextField
                    fullWidth
                    {...getTypeOfInput()}
                    {...props.inputProps}
                    //{...props.x}
                    label={props.label}
                    multiline={multiline ? true : undefined}
                    autoComplete={props.autoComplete ?? props.type}
                    helperText={props.viewModel.getError(fieldName)}
                    className={props.className}
                />
            );
        } else {
            return (
                <InputLabel id="demo-simple-select-label" className={props.className}>
                    {props.label}
                </InputLabel>
            );
        }
    };

    const checkBox = () => {
        return (
            <>
                <FormControlLabel
                    style={{ color: isInError() ? "red" : "black" }}
                    control={<Checkbox {...checkbox(fieldName, { validateOnBlur: props.validateOnBlur })} />}
                    label={props.label}
                    className={props.className}
                />
                {isInError() && (
                    <FormHelperText style={{ color: "red" }}>
                        {/*{errorMessage}*/}
                        {props.viewModel.getError(fieldName)}
                    </FormHelperText>
                )}
            </>
        );
    };

    const formatSelectValue = (value: any) => {
        let retval = props.selectItems!.find((a) => a.value == value);
        if (retval) return retval!.key;
        else return "";
    };

    return useObserver(() => <Box>{renderControl()}</Box>);
}

EditableInput.defaultProps = {
    type: "text",
    editMode: true,
    validateOnStart: false,
    validateOnBlur: true,
    fullwidth: true,
    inputProps: {
        multline: undefined,
    },
};
