import React, { useEffect, useState } from "react";
import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Dialog,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    Link,
    MenuItem,
    Modal,
    Paper,
    Select,
    Slide,
    TextField,
    Theme,
    Typography,
} from "@material-ui/core";
import { Centered, Show, TextFieldMemo } from "Core/Components";
import styled from "styled-components";
import { EditableInput } from "../../Core/Components/EditableInput";
import { RegisterUserViewModel } from "../ViewModels/RegisterUserViewModel";
import { nameofFactory } from "../../Core/Utils/Utils";
import { RegisterUserModel } from "../Models/RegisterUserModel";
import Radio from "@material-ui/core/Radio";
import { KeyValuePair } from "../../Core/Models/KeyValuePair";
import { LoginViewModel } from "../ViewModels/LoginViewModel";
import { CovidRegViewModel } from "../ViewModels/CovidRegViewModel";
import { CovidRegModel } from "../Models/CovidRegModel";
import { useObserver } from "mobx-react-lite";
import { CSSGrid } from "../../Core/Components/Styles";
import { useRouter } from "../../Core/Utils/Hooks";
import { runInAction } from "mobx";
import { StoresInstance } from "../Stores";
import { Covidcontainer } from "../StylesAppSpecific/HomeStyling";
import { LogoSwitcher } from "../Components/LogoSwitcher";
import { AutoGrid } from "../../Core/Components/AutoGrid";
import { FooterSwitcher } from "../Components/FooterSwitcher";
import { Link as RouterLink } from "react-router-dom";

const Main = styled(Box)`
    .covidcontainer {
        margin-top: 80px;
        overflow: hidden;
        margin-left: auto;
        margin-right: auto;
        background-color: rgb(255, 255, 255, 0.8);
        width: 960px;
        border-radius: 20px;
        padding: 60px 120px 120px 120px;
    }

    .covidcontainer-mobile {
        overflow: hidden;
        margin-left: auto;
        margin-right: auto;
        background-color: rgb(255, 255, 255, 0.8);
        margin-top: 0;
        //width: 960px;
        border-radius: 0;
        padding: 0 5px 5px 5px;
    }
`;

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function SignInOutButton(props: { onClick: () => void; text: string }) {
    let padding = "10px 30px 10px 30px";
    if (StoresInstance.coreStore.isMobile) {
        padding = "2px";
    }

    return (
        <Button onClick={props.onClick} style={{ padding: padding, borderRadius: "15px", textTransform: "none", width: "100%" }} variant={"contained"} color={"secondary"}>
            <Box p={5}>
                <Typography variant={StoresInstance.coreStore.isMobile ? "h5" : "h5"}>I am signing</Typography>
                <Typography variant={"h3"}>
                    <strong>{props.text}</strong>
                </Typography>
                <Typography variant={StoresInstance.coreStore.isMobile ? "h6" : "h6"}>click to continue</Typography>
            </Box>
        </Button>
    );
}

export const CovidRegPage: React.FunctionComponent = () => {
    const [viewModel] = useState(() => new CovidRegViewModel());
    const [errorMessage, setErrorMessage] = useState("");
    const [open, setOpen] = useState(false);

    const bind = viewModel.getContext();
    const { history, match } = useRouter();
    let { displayName } = match.params as any;

    useEffect(() => {
        if (displayName === undefined) {
            viewModel.history.push("/register");
        } else {
            let _ = viewModel.getSummary(displayName);
            let contact = JSON.parse(localStorage.getItem("contact") as string) as CovidRegModel;
            if (contact) {
                viewModel.model.setValue("firstName", contact.firstName);
                viewModel.model.setValue("lastName", contact.lastName);
                viewModel.model.setValue("phoneNumber", contact.phoneNumber);
                viewModel.model.setValue("company", contact.company);
            }
        }
    }, []);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        //setOpen(false);
        //viewModel.SetShowSignInPage(true);
    };

    const doSubmit = async (e: any) => {
        e.preventDefault();
        //isValid will check all fields to make sure they are in a valid state.
        if (!viewModel.isModelValid()) {
            setErrorMessage("Please fill out all of the required fields");
        } else {
            //setErrorMessage("No problem");
            localStorage.setItem(
                "contact",
                JSON.stringify({
                    firstName: viewModel.model.firstName,
                    lastName: viewModel.model.lastName,
                    phoneNumber: viewModel.model.phoneNumber,
                    company: viewModel.model.company,
                }),
            );

            let response = await viewModel.sendForm();
            if (response.wasSuccessful) {
                setOpen(true);
            } else {
                setErrorMessage(response.errors[0].message);
            }
        }
    };

    const getRegisterButton = () => {
        let btnText = "Register";
        if (viewModel.Summary.isHBPro) {
            if (viewModel.getValue<boolean>("signOut")) {
                btnText = "Sign Out";
            } else {
                btnText = "Sign In";
            }
        }

        return (
            <Button disabled={viewModel.IsLoading} type="submit" style={{ width: "350px" }} variant="contained" color="secondary">
                {viewModel.IsLoading ? <span>Please Wait...</span> : <span>{btnText}</span>}
            </Button>
        );
    };

    const signInPage = () => {
        return (
            <Box>
                <Typography variant={"body1"}>
                    To follow Government guidelines on the reopening of the hospitality industry, we require all visitors to register their details below to help the NHS Track &
                    Trace service.
                </Typography>
                <br />
                <br />
                <Typography variant={"body1"}>Please choose an option below to let our system know whether you are signing in or signing out:</Typography>
                <Box textAlign={"center"} padding={"20px 0 20px"}>
                    <AutoGrid desktopColumns={"1fr 1fr"} mobileColumns={"1fr 1fr"}>
                        <SignInOutButton onClick={() => viewModel.SignIn()} text={"IN"} />
                        <SignInOutButton onClick={() => viewModel.SignOut()} text={"OUT"} />
                    </AutoGrid>
                </Box>
                <Box>
                    <Typography variant={"h5"}>
                        <strong>What is ‘Housebuilder Pro Site Tracker’ ?</strong>
                    </Typography>
                    <br />
                    <Typography variant={"body1"}>
                        The Housebuilder Pro Covid-19 visitor\contractor sign in system is a free-to-use registration system designed to help businesses follow Government
                        guidelines in logging visitors to sites and offices around the UK.
                    </Typography>
                    <br />
                    <Typography variant={"body1"}>
                        Sign up takes less than 5 minutes and once you register your business, you will receive a unique QR code that you can print and display at your business
                        premises.
                    </Typography>
                    <br />
                    <Typography variant={"body1"}>
                        Your visitors or contractors can scan the code using their smart phones, which then takes them to a personalised registration page so you can keep an
                        accurate track of your visitors during these challenging times.
                    </Typography>
                    <br />
                    <Typography variant={"body1"}>
                        Once they have scanned the code, they will be presented with a "Sign in" or "Sign out" option so your visitor log is easily kept up-to-date.
                    </Typography>
                </Box>
            </Box>
        );
    };

    const mainPage = () => {
        return (
            <>
                <Box padding={"5px 0 20px"}>
                    <Typography variant={"h4"}>
                        <strong>Add your contact details here</strong>
                    </Typography>
                </Box>
                <form onSubmit={doSubmit}>
                    {errorMessage !== "" && (
                        <Typography variant="caption" style={{ color: "red" }}>
                            {errorMessage}
                        </Typography>
                    )}
                    <CSSGrid columns={viewModel.isMobile ? "1fr" : "1fr 1fr"} rowGap={viewModel.isMobile ? "10px" : "30px"} columnGap={"20px"}>
                        <EditableInput<CovidRegModel> viewModel={viewModel} label="First name (required" fieldName="firstName" />
                        <EditableInput<CovidRegModel> viewModel={viewModel} label="Last name (required)" fieldName="lastName" />
                        <Show iff={StoresInstance.domain.isHbProSite}>
                            <EditableInput<CovidRegModel> viewModel={viewModel} label="Company (required)" fieldName="company" />
                        </Show>
                        <EditableInput<CovidRegModel> viewModel={viewModel} label="Contact phone number (required)" fieldName="phoneNumber" />
                    </CSSGrid>
                    <Box padding={"30px 0 30px"}>
                        <EditableInput<CovidRegModel>
                            viewModel={viewModel}
                            type={"checkbox"}
                            label="I consent to share my information with the Government track and trace system if required"
                            fieldName="agreedConsent"
                        />
                    </Box>
                    <hr />
                    <Box paddingTop={"30px"} width={"100%"} textAlign={"center"}>
                        {getRegisterButton()}
                    </Box>
                </form>

                {/*<Dialog fullScreen={viewModel.isMobile} open={open} onClose={handleClose}>*/}
                <Dialog open={open} onClose={handleClose}>
                    <Box display={"flex"} style={{ height: "200px", marginTop: "0%" }} justifyContent={"center"} alignContent={"center"} alignItems={"center"} p={4}>
                        <Box>
                            <Typography variant={"h3"}>Thank you!</Typography>
                            <Typography variant={"body1"}>You can now close this page</Typography>
                            {/*<Button variant={"contained"} onClick={handleClose}>Close</Button>*/}
                        </Box>
                    </Box>
                    {/*<Button variant="contained" color="secondary" onClick={() => window.close()}>
                    Close
                </Button>*/}
                </Dialog>
            </>
        );
    };

    return useObserver(() => (
        <Box id={"maincontainer"} display={"flex"} height={"100%"} justifyContent={"center"} alignContent={"center"}>
            {!viewModel.showLoading && (
                <Covidcontainer id={"covidcontainer"} className={"mobile"}>
                    <Box>
                        <LogoSwitcher displayName={viewModel.Summary.displayName} />
                    </Box>
                    <Box textAlign={"right"}>
                        {!viewModel.showSignInPage && (
                            <Link
                                style={{ cursor: "pointer" }}
                                className="link under-input"
                                onClick={() => {
                                    viewModel.SetShowSignInPage(true);
                                }}
                            >
                                <Typography color={"secondary"} variant={"body1"}>
                                    back to start
                                </Typography>
                            </Link>
                        )}
                    </Box>
                    <Box textAlign={"left"} paddingTop={"5px"}>
                        <Box py={4}>
                            <Typography variant={"h4"}>
                                <strong>{viewModel.Summary.tradingName}</strong>
                            </Typography>
                        </Box>
                    </Box>
                    {!StoresInstance.domain.isHbProSite && (
                        <>
                            <Typography variant={"caption"}>
                                To follow Government guidelines on the reopening of the hospitality industry, we require all visitors to register their details below to help the
                                NHS Track & Trace service.
                            </Typography>
                            <Typography variant={"caption"}>
                                If someone who has visited our business tests positive for Covid-19, we are obliged to share details of all other patrons they may have come into
                                contact with.
                            </Typography>
                            <br />
                            <br />
                        </>
                    )}
                    {viewModel.showSignInPage ? signInPage() : mainPage()}
                    <FooterSwitcher />
                </Covidcontainer>
            )}
        </Box>
    ));
};
