import { styled } from "../../Core/Base";

export const HomeContainer = styled.div`
    span.header-sct {
        font-style: italic;
    }

    p.p1 {
        font-weight: bold;
    }

    p.paragraph {
        @media (max-width: 767px) {
            font-size: 0.75rem;
            letter-spacing: -0.18px;
        }
    }

    .button-link:hover {
        text-decoration: none;
    }

    img.sct {
        @media (max-width: 767px) {
            display: flex;
            margin: auto;
            height: fit-content;
        }
    }
`;
