import React, { useCallback, useEffect, useRef, useState } from "react";
import { Avatar, Box, Button, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, Link, MenuItem, Paper, Select, TextField, Theme, Typography } from "@material-ui/core";
import styled from "styled-components";
import { EditableInput } from "../../Core/Components/EditableInput";
import { RegisterUserViewModel } from "../ViewModels/RegisterUserViewModel";
import { RegisterUserModel } from "../Models/RegisterUserModel";
import { Cell, CSSGrid } from "../../Core/Components/Styles";
import SCTLogo from "Custom/Content/Images/sctlogo.png";
import { UploadLogo } from "./UploadLogo";
import { StoresInstance } from "../Stores";
import { useObserver } from "mobx-react-lite";
import { runInAction } from "mobx";
import { Link as RouterLink } from "react-router-dom";
import moment from "moment";
import { Covidcontainer } from "../StylesAppSpecific/HomeStyling";

const Infotext = styled(Box)`
    color: gray;
    font-size: 12px;
`;

export const Thumb = styled.img`
    max-height: 100px;
`;

export const Registrations: React.FC = () => {
    const [viewModel] = useState(() => new RegisterUserViewModel());
    let first = useRef(true);

    useEffect(() => {
        if (StoresInstance.domain.AccountStore.IsLoggedIn) {
            let _ = viewModel.getRegistrations();
        }
    }, []);

    return useObserver(() => (
        <Box id={"main"}>
            <Covidcontainer id={"covidcontainer"}>
                <Box display={"flex"} justifyContent={"space-between"}>
                    <Box display={"flex"} alignItems={"center"}>
                        <img crossOrigin={"anonymous"} style={{ height: "50px" }} id={"logo"} src={SCTLogo} alt={"Company Logo"} />
                        {StoresInstance.domain.AccountStore.IsLoggedIn && (
                            <>
                                <Box pl={"20px"}>
                                    <Button style={{ height: "30px" }} disabled={viewModel.IsLoading} variant="contained" color="secondary" onClick={() => viewModel.downloadCSV()}>
                                        Download spreadsheet
                                    </Button>
                                </Box>
                            </>
                        )}
                    </Box>
                    {StoresInstance.domain.AccountStore.IsLoggedIn && (
                        <Link component={RouterLink} className="link topright-link" to="/logout">
                            Logout
                        </Link>
                        // <Box>
                        //     <a style={{ cursor: "pointer" }} onClick={() => viewModel.history.push("/logout")}>
                        //         Logout
                        //     </a>
                        // </Box>
                    )}
                </Box>
                <Box display={"flex"} padding={"20px 0 20px"} justifyContent={"space-between"}>
                    <Typography variant={"h5"}>
                        {StoresInstance.domain.AccountStore.IsLoggedIn ? <strong>Registrations from the last 21 days</strong> : <strong>Sign up your business</strong>}
                    </Typography>
                </Box>
                <CSSGrid columns={"1fr"} alignContent={"center"}>
                    {!viewModel.isMobile && (
                        <CSSGrid style={{ borderBottom: "1px solid black" }} columns={"1fr 1fr 1fr 1fr"}>
                            <Box>
                                <strong>Date of Registration</strong>
                            </Box>
                            <Box>
                                <strong>First Name</strong>
                            </Box>
                            <Box>
                                <strong>Last Name</strong>
                            </Box>
                            <Box>
                                <strong>Phone Number</strong>
                            </Box>
                        </CSSGrid>
                    )}
                    <CSSGrid columns={"1fr 1fr 1fr 1fr"}>
                        {viewModel.Registrations.map((item, index) => {
                            return (
                                <>
                                    <Box key={index}>{moment(item.dateRegistered).format("HH:mm DD MMM YYYY")}</Box>
                                    <Box key={index}>{item.firstName}</Box>
                                    <Box key={index}>{item.lastName}</Box>
                                    <Box key={index}>{item.phoneNumber}</Box>
                                </>
                            );
                        })}
                    </CSSGrid>
                </CSSGrid>
            </Covidcontainer>
        </Box>
    ));
};
