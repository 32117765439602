import { DomainStores } from "./DomainStores";
/* eslint-disable @typescript-eslint/no-parameter-properties */
import * as Models from "../Models";
import * as MobX from "mobx";
import React, { createContext } from "react";
import { CoreStore } from "../../Core/Stores/CoreStore";
import { action, observable } from "mobx";

export class Stores {
    public coreStore: CoreStore = new CoreStore();
    public domain: DomainStores = new DomainStores();

    public constructor() {}

    public init(initialState: Models.InitialState) {
        this.coreStore.Init(this, initialState);
        this.domain.init(this, initialState);
    }
}
export const StoresInstance = new Stores();
export const StoresContext: React.Context<Stores> = createContext(StoresInstance);
