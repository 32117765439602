import { RegisterUserViewModel } from "../../ViewModels/RegisterUserViewModel";
import { Box, Button, Link, Typography } from "@material-ui/core";
import { StoresInstance } from "../../Stores";
import { Cell, CSSGrid } from "../../../Core/Components/Styles";
import { UploadLogo } from "../UploadLogo";
import { AutoGrid } from "../../../Core/Components/AutoGrid";
import { EditableInput } from "../../../Core/Components/EditableInput";
import { RegisterUserModel } from "../../Models/RegisterUserModel";
import React, { useState } from "react";
import { Thumb } from "./RegisterPage";
import styled from "styled-components";
import { runInAction } from "mobx";
import { useObserver } from "mobx-react-lite";
import { Show } from "../../../Core/Base";

const Infotext = styled(Box)`
    color: gray;
    font-size: 12px;
    padding-top: 5px;
`;

export function RegisterCompanyDetails(props: { viewModel: RegisterUserViewModel }) {
    const { viewModel } = props;
    const [errorMessage, setErrorMessage] = useState("");

    const doSubmit = async (e: any) => {
        e.preventDefault();
        if (viewModel.IsLoading) {
            return;
        }
        //isValid will check all fields to make sure they are in a valid state.
        if (!viewModel.isModelValid()) {
            setErrorMessage("Please fill out all of the required fields");
        } else {
            let response = await viewModel.register();
            StoresInstance.domain.CurrentUser = viewModel.getModel;
            if (response.wasSuccessful) {
                viewModel.history.push("/dashboard");
            } else {
                setErrorMessage(response.errors[0].message);
            }
        }
    };

    return useObserver(() => (
        <>
            <Box pt={3} display={"flex"} padding={viewModel.isMobile ? "0" : "20px 0 20px"} justifyContent={"space-between"}>
                <Typography variant={"h4"}>{StoresInstance.domain.AccountStore.IsLoggedIn ? <strong>Company Details</strong> : <strong>Sign up your business</strong>}</Typography>
            </Box>
            {errorMessage !== "" && (
                <>
                    <Typography variant="caption" style={{ color: "red" }}>
                        {errorMessage}
                    </Typography>
                    <br />
                </>
            )}
            <form onSubmit={doSubmit}>
                <CSSGrid columns={viewModel.isMobile ? "1fr" : "1fr 1fr"} rowGap={viewModel.isMobile ? "10px" : "30px"} columnGap={"20px"}>
                    {StoresInstance.domain.AccountStore.IsLoggedIn && viewModel.HasImage ? (
                        <Box>
                            <Thumb id={"registerlogo"} src={viewModel.getLogoUrl} />

                            <Box>
                                {/*<a style={{ cursor: "pointer" }} onClick={() => runInAction(() => (viewModel.HasImage = false))}>
                                    Remove
                                </a>*/}
                                <Link style={{ cursor: "pointer" }} className="link under-input" onClick={() => runInAction(() => (viewModel.HasImage = false))}>
                                    <Typography variant={"body1"} color={"secondary"}>
                                        Remove Logo
                                    </Typography>
                                </Link>
                            </Box>
                        </Box>
                    ) : (
                        <UploadLogo viewModel={viewModel} />
                    )}

                    {StoresInstance.domain.AccountStore.IsLoggedIn && (
                        <Box pl={"20px"}>
                            <Button disabled={viewModel.IsLoading} variant="contained" color="secondary" onClick={() => viewModel.downloadDocument()}>
                                Download my QR code PDF document
                            </Button>
                        </Box>
                    )}
                    {/*<Box>
                            <EditableInput<RegisterUserModel> viewModel={viewModel} autoComplete={"organization-title"} label="Position (required)" fieldName="position" />
                            <Infotext>What is the position you hold at your business</Infotext>
                        </Box>*/}
                </CSSGrid>

                <Box py={4}>
                    <AutoGrid desktopColumns={"1fr 1fr"} mobileColumns={"1fr"} rowGap={viewModel.isMobile ? "10px" : "30px"} columnGap={"20px"}>
                        <Show iff={StoresInstance.domain.isHbProSite}>
                            <Cell width={viewModel.isMobile ? 1 : 2} style={{ width: "49%" }}>
                                <EditableInput<RegisterUserModel> viewModel={viewModel} autoComplete={"organization"} label="Company name (required)" fieldName="tradingName" />
                                <Infotext>Name of your business</Infotext>
                            </Cell>
                        </Show>
                        <EditableInput<RegisterUserModel> viewModel={viewModel} autoComplete={"given-name"} label="Contact first name (required)" fieldName="firstName" />
                        <EditableInput<RegisterUserModel> viewModel={viewModel} autoComplete={"family-name"} label="Contact last name (required)" fieldName="lastName" />
                        <EditableInput<RegisterUserModel> viewModel={viewModel} autoComplete={"tel"} label="Contact phone number (required)" fieldName="phoneNumber" />
                        <EditableInput<RegisterUserModel> viewModel={viewModel} autoComplete={"email"} type={"email"} label="Contact email address (required)" fieldName="email" />
                    </AutoGrid>
                </Box>

                <Box padding={"20px 0 20px"}>
                    <Typography variant={"h4"}>
                        <strong>Account details</strong>
                    </Typography>
                </Box>
                <CSSGrid columns={viewModel.isMobile ? "1fr" : "1fr 1fr 1fr 1fr"} rowGap={viewModel.isMobile ? "10px" : "30px"} columnGap={"20px"}>
                    <Cell width={viewModel.isMobile ? 1 : 2}>
                        <Box>
                            {StoresInstance.domain.AccountStore.IsLoggedIn ? (
                                <>
                                    <EditableInput<RegisterUserModel>
                                        alphaOnly={true}
                                        editMode={false}
                                        viewModel={viewModel}
                                        autoComplete={"organization"}
                                        label="Display name"
                                        fieldName="displayName"
                                    />
                                    <Box py={0.75} borderBottom={"1px solid gray"}>
                                        {viewModel.getValue("displayName")}
                                    </Box>
                                    <Infotext>This cannot be edited please contact us on 01743 636300 if it needs to be changed</Infotext>
                                </>
                            ) : (
                                <>
                                    <EditableInput<RegisterUserModel>
                                        alphaOnly={true}
                                        viewModel={viewModel}
                                        autoComplete={"organization"}
                                        label="Display name (required)"
                                        fieldName="displayName"
                                    />
                                    {StoresInstance.domain.isHbProSite ? (
                                        <Infotext>
                                            The name that will be used on the customer url e.g https://sitetracker.housebuilderpro.co.uk/qr/shoothill. Only characters a to z
                                            allowed.
                                        </Infotext>
                                    ) : (
                                        <Infotext>
                                            The name that will be used on the customer url e.g https://shoothillcovidtracer.com/qr/shoothill. Only characters a to z allowed.
                                        </Infotext>
                                    )}
                                </>
                            )}
                        </Box>
                    </Cell>
                </CSSGrid>
                <AutoGrid desktopColumns={"1fr 1fr"} mobileColumns={"1fr"} rowGap={viewModel.isMobile ? "10px" : "30px"} columnGap={"20px"}>
                    <Box>
                        <EditableInput<RegisterUserModel> viewModel={viewModel} type={"password"} autoComplete={"new-password"} label="Password (required)" fieldName="password" />
                        <Infotext>More than 8 characters & contain at least one capital letter, number & symbol</Infotext>
                    </Box>
                    <EditableInput<RegisterUserModel> viewModel={viewModel} type={"password"} autoComplete={"new-password"} label="Confirm Password" fieldName="confirmPassword" />
                </AutoGrid>
                {!StoresInstance.domain.AccountStore.IsLoggedIn && (
                    <>
                        <AutoGrid padding={"20px 0 20px 0"} desktopColumns={"1fr 1fr"} mobileColumns={"1fr"}>
                            <Box>
                                <EditableInput<RegisterUserModel>
                                    viewModel={viewModel}
                                    type={"checkbox"}
                                    label={
                                        <span>
                                            <strong>
                                                I accept the
                                                {StoresInstance.domain.isHbProSite ? <span> Housebuilder Pro Ltd Covid Tracer </span> : <span> Shoothill Covid Tracer </span>}
                                                {StoresInstance.domain.isHbProSite ? (
                                                    <a href="/CovidTracerTerms.pdf" target="_blank">
                                                        Terms & Conditions
                                                    </a>
                                                ) : (
                                                    <a href="https://shoothill.com/wp-content/uploads/2020/07/Terms-and-Conditions.pdf" target="_blank">
                                                        Terms & Conditions
                                                    </a>
                                                )}
                                            </strong>
                                        </span>
                                    }
                                    fieldName="terms"
                                />
                            </Box>
                            <Box>
                                <EditableInput<RegisterUserModel>
                                    viewModel={viewModel}
                                    type={"checkbox"}
                                    label={
                                        <span>
                                            <strong>We may wish to contact you from time to time. Please tick the box if you give your consent to do so</strong>
                                        </span>
                                    }
                                    fieldName="agreedConsent"
                                />
                            </Box>
                        </AutoGrid>
                        <Box p={1} width={"100%"} textAlign={"center"}>
                            <Button disabled={viewModel.IsLoading} type="submit" style={{ width: "350px" }} variant="contained" color="secondary">
                                {viewModel.IsLoading ? <span>Please Wait...</span> : <span>Register</span>}
                            </Button>
                        </Box>
                    </>
                )}
                {StoresInstance.domain.AccountStore.IsLoggedIn && (
                    <>
                        <Box paddingTop={"30px"} width={"100%"} textAlign={"center"}>
                            <Button disabled={viewModel.IsLoading} type="submit" style={{ width: "350px" }} variant="contained" color="secondary">
                                {viewModel.IsLoading ? <span>Please Wait...</span> : <span>Save</span>}
                            </Button>
                        </Box>
                    </>
                )}
            </form>
        </>
    ));
}
