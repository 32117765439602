import { ModelBase } from "Core/Models/ModelBase";
import * as MobX from "mobx";
import { validate, validateOrReject, Contains, IsInt, Length, IsEmail, IsFQDN, IsDate, Min, Max, IsNotEmpty, ValidateIf, Equals } from "class-validator";
import { RegisterAddressModel } from "./RegisterUserAddressModel";
import { BlankModelDTO } from "../../Core/Models/BlankModel";

export class RegisterUserModel extends ModelBase<RegisterUserModel, RegisterUserModel> {
    @MobX.observable
    @IsNotEmpty({ message: "Trading name is required" })
    public tradingName: string = "";

    public logoBase64: string = "";

    @MobX.observable
    //@IsNotEmpty({ message: "Unique name is required" })
    public displayName: string = "";

    @MobX.observable
    @IsNotEmpty({ message: "Last name is required" })
    public lastName: string = "";

    @MobX.observable
    @IsNotEmpty({ message: "First name is required" })
    public firstName: string = "";

    @MobX.observable
    //@IsNotEmpty({ message: "Position is required" })
    public position: string = "";

    @MobX.observable
    @IsNotEmpty({ message: "Email address is required" })
    @IsEmail({}, { message: "Email address is not correct" })
    public email: string = "";

    @MobX.observable
    @IsNotEmpty({ message: "Phone number is required" })
    public phoneNumber: string = "";

    @MobX.observable
    @IsNotEmpty({ message: "Password is required" })
    public password: string = "";

    @MobX.observable
    public confirmPassword: string = "";

    @MobX.observable
    @Equals(true, { message: "Must be ticked" })
    public terms: boolean = false;

    @MobX.observable
    public contactConsent: boolean = false;

    @MobX.observable
    public isHBPro: boolean = false;

    fromDto(model: RegisterUserModel): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }
    public toDto = (model: RegisterUserModel) => {};
}
